import { OrgRole, WithAccessControlMetadata } from '@grafana/data';

type ContextUser = {
  id: number;
  email: string;
  name: string;
  orgId: number;
  orgName: string;
  orgRole: OrgRole | '';
};

export enum AccessControlAction {
  DataSourcesRead = 'datasources:read',
  DataSourcesCreate = 'datasources:create',
  DataSourcesWrite = 'datasources:write',
  PrivateNetworksRead = 'grafana-pdc-app.private-networks:read',
}
export interface ContextServType {
  isSignedIn: boolean;
  isGrafanaAdmin: boolean;
  isEditor?: boolean;
  hasRole?: (s: string) => boolean;
  user?: ContextUser;
  hasPermissionInMetadata(action: AccessControlAction, object: WithAccessControlMetadata): boolean;
  hasPermission(action: AccessControlAction): boolean;
}
