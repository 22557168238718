import React from 'react';
import { Provider } from 'react-redux';

import { AppApiContext, store } from 'feature/common';
import { PDCPluginExtensionDataSourceConfigContext } from 'feature/datasource-config/types';

import { DataSourceExtensionConfig } from './DataSourceExtensionConfig';
import { DataSourceExtensionContextProvider } from './DataSourceExtensionContextProvider';

export const DataSourceExtension = ({ context }: { context: PDCPluginExtensionDataSourceConfigContext }) => {
  return (
    <Provider store={store} context={AppApiContext}>
      <DataSourceExtensionContextProvider context={context}>
        <DataSourceExtensionConfig context={context}></DataSourceExtensionConfig>
      </DataSourceExtensionContextProvider>
    </Provider>
  );
};
