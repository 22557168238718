import { css } from '@emotion/css';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { SelectGroup } from '@grafana-cloud/ui';
import { useAppDispatch, useRudderStack } from 'feature/common';
import { setInstallationOptionValue } from 'feature/private-networks/state/privateNetworkDetailSlice';
import { InstallMethod } from 'feature/private-networks/types';

export const ConfigurationInstructionsMethod = () => {
  const { id: privateNetworkId = '' } = useParams<{ id: string }>();
  const styles = useStyles2(getStyles);
  const { trackRudderStackEvent } = useRudderStack();

  const dispatch = useAppDispatch();

  const installationOptions = [
    {
      label: 'Kubernetes',
      value: InstallMethod.KUBERNETES,
      description: 'Running on Kubernetes',
    },
    {
      label: 'Docker',
      value: InstallMethod.DOCKER,
      description: 'Running the PDC Agent Docker image',
    },
    {
      label: 'Binary',
      value: InstallMethod.BINARY,
      description: 'Running the PDC Agent binary directly',
    },
  ];
  const [option, setOption] = useState(InstallMethod.KUBERNETES);

  return (
    <>
      <h5>Choose your installation method</h5>
      <SelectGroup
        options={installationOptions}
        value={option}
        onChange={(val: InstallMethod) => {
          if (option !== val) {
            trackRudderStackEvent('grafana_pdc_plugin_configuration_installation_method_selected', {
              installation_method: val,
            });
            setOption(val);
            dispatch(
              setInstallationOptionValue({
                installationOptionValue: val,
                id: privateNetworkId,
              })
            );
          }
        }}
        size="md"
        className={styles.selectGroup}
      />
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  selectGroup: css({
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  }),
});
