import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { DataSource } from 'feature/common';
import { DataSourcesList } from 'feature/private-networks/components/PrivateNetworkDetail/OverviewTab/DataSources/DataSourcesList';

type Props = {
  dataSources: DataSource[];
};

export const DataSources = ({ dataSources }: Props) => {
  const styles = useStyles2(getStyles);

  return (
    <section className={styles.section}>
      <h2>Data sources</h2>
      <p>
        These are the data sources configured to use this PDC network. To add more data sources, go to the settings tab
        of the data source and select the name of your PDC network under Private data source connect.
      </p>
      <DataSourcesList dataSources={dataSources} />
    </section>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  section: css({
    marginBottom: '3rem',
  }),
});
