import React from 'react';

import { TestingStatus } from '@grafana/runtime';
import { Alert } from '@grafana/ui';

export const DataSourceExtensionTestingStatusError = ({}: { testingStatus: TestingStatus }) => {
  return (
    <Alert severity="error" title="">
      There was an error when testing this data source, please check PDC agent logs.
    </Alert>
  );
};
