import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, useStyles2 } from '@grafana/ui';

import { TextLink } from 'feature/common/components/TextLink';

export const DataSourceExtensionConfigLink = () => {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.linkContainer}>
      <TextLink href="/connections/private-data-source-connections/">
        <Icon name="arrow-right" />
        Manage private data source connect
      </TextLink>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  linkContainer: css({
    marginTop: theme.spacing(2),
  }),
});
