import { css } from '@emotion/css';
import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, CollapsableSection, Field, Form, Icon, IconButton, Input, useStyles2 } from '@grafana/ui';

import { AppContext, useRudderStack, validateHost } from 'feature/common';
import { useUpdatePrivateNetworkMutation } from 'feature/private-networks/api/PrivateNetworksApi';
import { usePrivateNetwork } from 'feature/private-networks/hooks/usePrivateNetwork';

export const ConfigurationInstructionsDeploymentLimitHosts = () => {
  const styles = useStyles2(getStyles);
  const { trackRudderStackEvent } = useRudderStack();
  const { id: privateNetworkId = '' } = useParams<{ id: string }>();
  const { region, stackId } = useContext(AppContext);

  const { data: privateNetworkData } = usePrivateNetwork(privateNetworkId);

  const allowedHosts = privateNetworkData?.allowedHosts ?? [];

  const [isOpen, setIsOpen] = useState(allowedHosts.length > 0);

  const [updatePrivateNetwork] = useUpdatePrivateNetworkMutation();

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const onAdd = (hostUrl: string) => {
    trackRudderStackEvent('grafana_pdc_plugin_configuration_allowed_host_added', {
      number_of_allowed_hosts: allowedHosts.length + 1,
    });
    if (privateNetworkData) {
      const privateNetwork = {
        ...privateNetworkData,
        allowedHosts: [...privateNetworkData.allowedHosts, hostUrl],
      };
      updatePrivateNetwork({
        privateNetwork,
        region,
        stackId,
      });
    }
  };

  const onDelete = (index: number) => {
    trackRudderStackEvent('grafana_pdc_plugin_configuration_allowed_host_removed', {
      number_of_allowed_hosts: allowedHosts.length - 1,
    });
    if (privateNetworkData) {
      const privateNetwork = {
        ...privateNetworkData,
        allowedHosts: privateNetworkData.allowedHosts.filter((_, i) => i !== index),
      };
      updatePrivateNetwork({
        privateNetwork,
        region,
        stackId,
      });
    }
  };

  return (
    <div className={styles.section}>
      <CollapsableSection
        className={styles.collapse}
        contentClassName={styles.collapseContent}
        isOpen={isOpen}
        onToggle={onToggle}
        label={<p className={styles.subTitle}>(Optional) Limit hosts Grafana Cloud can access</p>}
      >
        <p>Configure the agent to limit allowed hostnames using the PermitRemoteOpen SSH flag.</p>
        <Form
          validateOn="onChange"
          onSubmit={async (formData: { hostUrl: string }) => {
            onAdd(formData.hostUrl.trim());
          }}
        >
          {({ register, errors, watch, setValue, getValues, reset }) => {
            return (
              <>
                <Field
                  className={styles.field}
                  label="Allowed hosts"
                  description="The hosts you add below will be the only hosts Grafana Cloud can access."
                  invalid={!!errors.hostUrl}
                  error={errors?.hostUrl?.message}
                >
                  <>
                    {allowedHosts.length > 0 &&
                      allowedHosts.map((host, index) => (
                        <div key={index} className={styles.disabledInputGroup}>
                          <Input
                            className={styles.disabledInput}
                            type="text"
                            id={'allowedHost' + index}
                            aria-label={'Allowed host ' + index}
                            disabled
                            value={host}
                          />
                          <IconButton
                            name="trash-alt"
                            onClick={() => {
                              onDelete(index);
                              // adding a workaround for clearing the validation error till 10.2.x is released
                              // https://github.com/grafana/grafana/pull/73831
                              setTimeout(() => {
                                reset();
                              });
                            }}
                            aria-label="Delete"
                          />
                        </div>
                      ))}
                    <Input
                      type="text"
                      id="allowedHost"
                      aria-label="Allowed host"
                      placeholder="mysql.example.com:3306"
                      noValidate
                      {...register('hostUrl', {
                        validate: (value) => validateHost(value, allowedHosts),
                        required: { value: true, message: 'Host is required' },
                      })}
                    />
                  </>
                </Field>
                <Button
                  type="submit"
                  disabled={!watch('hostUrl') || !!errors.hostUrl}
                  onClick={() => setTimeout(() => reset())}
                >
                  <Icon className={styles.icon} name="plus" size="lg" />
                  Add host
                </Button>
              </>
            );
          }}
        </Form>
      </CollapsableSection>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  section: css({
    marginBottom: theme.spacing(4),
  }),
  subTitle: css({
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.body.fontSize,
    margin: 0,
  }),
  icon: css({
    marginRight: '1rem',
  }),
  inputGroup: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  }),
  field: css({
    width: '100%',
    flexGrow: 1,
    marginRight: '1rem',
  }),
  input: css({
    marginBottom: '1rem',
  }),
  disabledInputGroup: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '1rem',
  }),
  disabledInput: css({
    width: '100%',
    flexGrow: 1,
    marginRight: '1rem',
  }),
  collapse: css({
    padding: 12,
    background: theme.colors.background.primary,
    border: `1px solid ${theme.colors.border.medium}`,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    ':hover': {
      background: theme.colors.background.secondary,
      boxShadow: 'inset 0 0 1px black',
    },
  }),
  collapseContent: css({
    padding: 12,
    borderBottom: `1px solid ${theme.colors.border.medium}`,
    borderLeft: `1px solid ${theme.colors.border.medium}`,
    borderRight: `1px solid ${theme.colors.border.medium}`,
  }),
});
