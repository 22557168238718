import { getRandomShortId } from 'feature/common';

export function getDefaultTokenName(): string {
  return `pdc-${getRandomShortId()}`;
}

export function getRandomConnectionName(slug: string) {
  return `pdc-${slug}-${getRandomShortId()}`;
}

export function getDefaultAccessPolicyName(slug: string): string {
  return `pdc-${slug}-default`;
}
