export const validateExistingToken = (existingToken: string) => {
  const trimmedToken = existingToken.trim();
  if (trimmedToken === '') {
    return true;
  }
  const tokenParts = trimmedToken.split('_');
  if (tokenParts.length < 2) {
    return 'Token must be in format glc_...';
  }
  if (tokenParts[0] !== 'glc') {
    return 'Token must start with glc';
  }
  if (tokenParts[tokenParts.length - 1] === '') {
    return 'Token must be in format glc_...';
  }
  try {
    atob(tokenParts[tokenParts.length - 1]);
  } catch (e) {
    return 'Token must be in format glc_...';
  }
  return true;
};
