export const displayNameToName = (displayName: string): string => {
  return !!displayName
    ? displayName
        .toLowerCase()
        .trim()
        .replace(/\s\s+/g, ' ')
        .replace(/[^a-z0-9.-]/g, '-')
        .slice(0, 63)
    : '';
};

export const validateConnectionName = (name: string, existingNames: string[]): boolean | string => {
  const trimmedName = name.trim();
  if (trimmedName === '') {
    return 'Name cannot be an empty string';
  }
  if (existingNames.includes(trimmedName)) {
    return 'Name already exists';
  }
  return true;
};
