import {
  AccessPolicy,
  AccessPolicyModel,
  RealmTypes,
  transformAccessPolicyModelToAccessPolicy,
} from '@grafana-cloud/access-policies';
import { SortType } from 'consts';
import { displayNameToName } from 'feature/common';
import { dateStrToMs } from 'feature/common/utils/date';
import { ConnectionInfo } from 'feature/private-networks/api/ConnectionStatusApi';
import { PrivateNetwork } from 'feature/private-networks/types';
import { ConnectionStatus } from 'feature/private-networks/types/ConnectionStatus';

export function filterAndSortPrivateNetworks(
  privateNetworks: PrivateNetwork[],
  filter: string,
  sort: SortType
): PrivateNetwork[] {
  return privateNetworks
    .filter((privateNetwork) => {
      return privateNetwork.displayName?.toLowerCase().includes(filter?.toLocaleLowerCase());
    })
    .sort((a, b) => {
      switch (sort) {
        case SortType.Alphabetical:
          return a.displayName?.localeCompare(b.displayName);
        case SortType.ReverseAlphabetical:
          return b.displayName?.localeCompare(a.displayName);
        case SortType.CreatedDate:
          return dateStrToMs(b.createdAt) - dateStrToMs(a.createdAt);
        case SortType.ReverseCreatedDate:
          return dateStrToMs(a.createdAt) - dateStrToMs(b.createdAt);
        default:
          return dateStrToMs(b.createdAt) - dateStrToMs(a.createdAt);
      }
    });
}

export function lookupDomain(environment?: string) {
  let domain;
  switch (environment) {
    case 'dev':
      domain = 'grafana-dev.net';
      break;
    case 'ops':
      domain = 'grafana-ops.net';
      break;
    default:
      break;
  }
  return domain;
}

export function lookupGcomUrl(environment?: string) {
  let url;
  switch (environment) {
    case 'prod':
      url = 'https://grafana.com';
      break;
    case 'dev':
      url = 'https://grafana-dev.com';
      break;
    case 'ops':
      url = 'https://grafana-ops.com';
      break;
    default:
      break;
  }
  return url;
}

export function getConnectionStatus(
  connectionInfo: ConnectionInfo | undefined,
  isLoading: boolean,
  isError: boolean
): ConnectionStatus {
  if (isLoading) {
    return ConnectionStatus.LOADING;
  } else if (isError) {
    return ConnectionStatus.NOT_AVAILABLE;
  } else if (!connectionInfo) {
    return ConnectionStatus.NOT_CONNECTED;
  } else {
    return connectionInfo.numConnectedAgents > 0 ? ConnectionStatus.CONNECTED : ConnectionStatus.NOT_CONNECTED;
  }
}

export const getConnectionStatusText = (connectionStatus: ConnectionStatus, amountConnectedAgents: number): string => {
  switch (connectionStatus) {
    case ConnectionStatus.CONNECTED:
      if (amountConnectedAgents === 1) {
        return '1 agent connected';
      }
      if (amountConnectedAgents > 1) {
        return amountConnectedAgents + ' agents connected';
      }
      return 'Connection status not available';
    case ConnectionStatus.NOT_CONNECTED:
      return '0 agents connected';
    case ConnectionStatus.NOT_AVAILABLE:
      return 'Connection status not available';
    case ConnectionStatus.LOADING:
      return 'Loading...';
  }
};

export const accessPolicyToPrivateNetwork = (accessPolicy: AccessPolicyModel): PrivateNetwork => {
  return {
    accessPolicyId: accessPolicy.id,
    name: accessPolicy.name,
    displayName: accessPolicy.displayName,
    status: accessPolicy.status,
    id: accessPolicy.id,
    createdAt: accessPolicy.createdAt,
    allowedHosts: accessPolicy.attributes?.pdcConfiguration?.limitedHosts || [],
  };
};

export const PDC_SIGNING_SCOPE = 'set:pdc-signing';
export const LEGACY_PDC_SIGNING_SCOPE = 'pdc-signing:write';

export const privateNetworkToAccessPolicyModel = (
  privateNetwork: Partial<PrivateNetwork>,
  stackId: string
): Partial<AccessPolicyModel> => {
  const accessPolicy: Partial<AccessPolicyModel> = {
    ...privateNetwork,
    scopes: [PDC_SIGNING_SCOPE],
    realms: [{ identifier: stackId, type: RealmTypes.STACK, labelPolicies: [] }],
    name: privateNetwork.name || displayNameToName(privateNetwork.displayName!),
    displayName: privateNetwork.displayName,
    status: privateNetwork.status,
  };
  if (privateNetwork.allowedHosts) {
    accessPolicy.attributes = {
      pdcConfiguration: {
        limitedHosts: privateNetwork.allowedHosts,
      },
    };
  }
  return accessPolicy;
};

export const privateNetworkToAccessPolicy = (
  privateNetwork: PrivateNetwork,
  stackId: string,
  region: string
): AccessPolicy => {
  return transformAccessPolicyModelToAccessPolicy(
    {
      ...privateNetwork,
      scopes: [PDC_SIGNING_SCOPE],
      realms: [{ identifier: stackId, type: RealmTypes.STACK, labelPolicies: [] }],
      name: privateNetwork.name || displayNameToName(privateNetwork.displayName!),
      displayName: privateNetwork.displayName,
      status: privateNetwork.status,
      attributes: {
        pdcConfiguration: {
          limitedHosts: privateNetwork.allowedHosts,
        },
      },
    },
    region
  );
};
