import { css } from '@emotion/css';
import React from 'react';

import { Card, useStyles2 } from '@grafana/ui';

import { DataSource } from 'feature/common';

type Props = {
  dataSource: DataSource;
  // onDelete: (dataSource: DataSource) => void;
};

export const DataSourcesItem = ({
  dataSource,
}: // onDelete
Props) => {
  const styles = useStyles2(getStyles);
  return (
    <Card href={`/datasources/edit/` + dataSource.uid}>
      <Card.Heading>{dataSource.name}</Card.Heading>
      <Card.Figure>
        <img
          src={dataSource.typeLogoUrl}
          alt={dataSource.name + ' logo'}
          className={styles.dataSourceIcon}
          height="40px"
          width="40px"
        />
      </Card.Figure>
      {/* <Card.Tags>
      <IconButton
        className={styles.trashIcon}
        name="trash-alt"
        onClick={() => {
          onDelete(dataSource);
        }}
      />
      </Card.Tags> */}
    </Card>
  );
};

const getStyles = () => ({
  dataSourceIcon: css({
    display: 'flex',
    alignItems: 'center',
    maxWidth: '2rem',
  }),
  trashIcon: css({
    display: 'flex',
    justifySelf: 'end',
    margin: 0,
  }),
});
