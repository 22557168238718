import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import {
  ConfigurationInstructionsAssignDataSource,
  ConfigurationInstructionsDeployment,
  ConfigurationInstructionsUseToken,
} from 'feature/private-networks/components';
import { PrivateNetwork } from 'feature/private-networks/types';

import { ConfigurationInstructionsMethod } from './ConfigurationInstructionsMethod';

type Props = {
  privateNetwork: PrivateNetwork;
};

export const ConfigurationInstructions = ({ privateNetwork }: Props) => {
  const styles = useStyles2(getStyles);

  return (
    <ol className={styles.instructionList}>
      <li className={styles.listItems}>
        <ConfigurationInstructionsMethod />
      </li>
      <li className={styles.listItems}>
        <ConfigurationInstructionsUseToken />
      </li>
      <li className={styles.listItems}>
        <ConfigurationInstructionsDeployment />
      </li>
      <li className={styles.listItems}>
        <ConfigurationInstructionsAssignDataSource />
      </li>
    </ol>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  instructionList: css({
    marginLeft: theme.spacing(2),
  }),
  listItems: css({
    marginBottom: theme.spacing(6),
    label: {
      fontSize: theme.typography.bodySmall.fontSize,
      marginRight: theme.spacing(3),
    },
  }),
});
