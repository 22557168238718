import { css } from '@emotion/css';
import React from 'react';
import { useParams } from 'react-router-dom';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Icon, useStyles2 } from '@grafana/ui';

import { ConnectionStatus, useAppSelector, useRudderStack } from 'feature/common';
import { useGetConnectionStatusQuery } from 'feature/private-networks/api/ConnectionStatusApi';
import {
  ConfigurationInstructionsDeploymentBinary,
  ConfigurationInstructionsDeploymentDocker,
  ConfigurationInstructionsDeploymentKubernetes,
  ConfigurationInstructionsDeploymentLimitHosts,
} from 'feature/private-networks/components';
import { getPrivateNetworkDetailState } from 'feature/private-networks/state/privateNetworkDetailSlice';
import { InstallMethod } from 'feature/private-networks/types';

export const ConfigurationInstructionsDeployment = () => {
  const styles = useStyles2(getStyles);
  const { trackRudderStackEvent } = useRudderStack();

  let installationInstructions: JSX.Element = <></>;
  const { id: privateNetworkId = '' } = useParams<{ id: string }>();

  const installationOptionValue = useAppSelector(
    (state) => getPrivateNetworkDetailState(state, privateNetworkId).installationOptionValue
  );

  switch (installationOptionValue) {
    case InstallMethod.KUBERNETES:
      installationInstructions = <ConfigurationInstructionsDeploymentKubernetes />;
      break;
    case InstallMethod.DOCKER:
      installationInstructions = <ConfigurationInstructionsDeploymentDocker />;
      break;
    case InstallMethod.BINARY:
      installationInstructions = <ConfigurationInstructionsDeploymentBinary />;
      break;
    default:
      installationInstructions = <></>;
  }

  const { isFetching, isLoading, refetch } = useGetConnectionStatusQuery();

  const testAgentConnection = async () => {
    const { data: _data } = await refetch();
    const connectedAgents = _data?.byId[privateNetworkId]?.[0].numConnectedAgents;
    trackRudderStackEvent('grafana_pdc_plugin_configuration_deployment_step_agent_connection_tested', {
      number_of_connected_agents: connectedAgents,
    });
  };

  return (
    <>
      <h5>Deploy the PDC agent on your private network</h5>
      <ConfigurationInstructionsDeploymentLimitHosts />
      {installationInstructions}
      <p>
        (Optional) For high availability, you can install additional instances of the agent on your network with the
        same configuration. These can be deployed to different regions, data centers, or providers as long as they are
        on the same network.
      </p>
      <div className={styles.testButtonContainer}>
        <Button
          className={styles.button}
          size="md"
          onClick={() => testAgentConnection()}
          disabled={isFetching || isLoading}
        >
          {(isFetching || isLoading) && <Icon className={styles.spinnerIcon} name="fa fa-spinner" />}
          Test agent connection
        </Button>
        {<ConnectionStatus privateNetworkId={privateNetworkId} showRefresh={false}></ConnectionStatus>}
      </div>
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  spinnerIcon: css({
    marginRight: 5,
  }),
  testButtonContainer: css({
    display: 'flex',
  }),
  button: css({
    marginRight: theme.spacing(2),
  }),
});
