import React from 'react';

import { containerMaxWidthCss } from 'feature/common';
import { TabProps } from 'feature/private-networks/components/PrivateNetworkDetail/Tabs/Tabs';

import { ConfigurationInstructions } from './ConfigurationInstructions/ConfigurationInstructions';
import { ConfigurationQuestionSection } from './ConfigurationQuestionSection';

export const ConfigurationTab = ({ privateNetwork }: TabProps) => {
  return (
    <div className={containerMaxWidthCss}>
      <ConfigurationInstructions privateNetwork={privateNetwork} />
      <ConfigurationQuestionSection />
    </div>
  );
};
