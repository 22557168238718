import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, createDispatchHook, createSelectorHook } from 'react-redux';

import { AppApi, AppApiContext } from 'feature/common/api';
import { privateNetworkDetailSlice } from 'feature/private-networks/state/privateNetworkDetailSlice';

export const store = configureStore({
  reducer: {
    [AppApi.reducerPath]: AppApi.reducer,
    privateNetworkDetail: privateNetworkDetailSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(AppApi.middleware),
});

export type AppState = ReturnType<typeof store.getState>;
export const useAppDispatch = createDispatchHook<AppState>(AppApiContext);
export const useAppSelector: TypedUseSelectorHook<AppState> = createSelectorHook(AppApiContext);
