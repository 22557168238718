import { createContext } from 'react';

import { DataSourceSettings } from '@grafana/data';

import { PDCDataSourceJSONData } from 'feature/common';
interface DataSourceExtensionContextData {
  dataSource?: DataSourceSettings<PDCDataSourceJSONData>;
}

export const DataSourceExtensionContext = createContext<DataSourceExtensionContextData>({});
