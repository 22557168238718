import { css } from '@emotion/css';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

import { PRIVATE_NETWORKS_PATH } from 'consts';
import { containerMaxWidthCss } from 'feature/common';
import { TabProps } from 'feature/private-networks/components/PrivateNetworkDetail/Tabs/Tabs';
import { DeletePrivateNetworkModal } from 'feature/private-networks/components/PrivateNetworkList/DeletePrivateNetworkModal';
import { PrivateNetwork } from 'feature/private-networks/types/PrivateNetwork';

import { DataSources } from './DataSources/DataSources';
import { InformationSection } from './InformationSection';
import { Tokens } from './Tokens/Tokens';

export const OverviewTab = ({ privateNetwork, dataSources }: TabProps) => {
  const styles = getStyles();

  const [deletePrivateNetwork, setDeletePrivateNetwork] = useState<PrivateNetwork | undefined>();
  const [shouldRedirect, setShouldRedirect] = useState(false);

  if (shouldRedirect) {
    return <Navigate to={PRIVATE_NETWORKS_PATH} />;
  }

  return (
    <div className={styles.container}>
      {deletePrivateNetwork && (
        <DeletePrivateNetworkModal
          privateNetwork={deletePrivateNetwork}
          onClose={() => setDeletePrivateNetwork(undefined)}
          onDelete={() => setShouldRedirect(true)}
        />
      )}{' '}
      <InformationSection privateNetwork={privateNetwork} />
      <Tokens privateNetwork={privateNetwork} />
      <DataSources dataSources={dataSources} />
    </div>
  );
};

const getStyles = () => ({
  container: css([
    containerMaxWidthCss,
    {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
    },
  ]),
});
