import { buildCreateApi, coreModule, reactHooksModule } from '@reduxjs/toolkit/query/react';
import React, { createContext } from 'react';
import { ReactReduxContextValue, createDispatchHook, createSelectorHook, createStoreHook } from 'react-redux';

// using any here as we get a type error when using linked repo on local dev (VisualCode only, yarn dev works fine)
// TODO: investigate more
export const AppApiContext: React.Context<ReactReduxContextValue> = createContext({} as ReactReduxContextValue);

export const createApi = buildCreateApi(
  coreModule(),
  reactHooksModule({
    useDispatch: createDispatchHook(AppApiContext),
    useSelector: createSelectorHook(AppApiContext),
    useStore: createStoreHook(AppApiContext),
  })
);
