import React from 'react';

import { usePluginContext } from '@grafana/data';

import { AppContext } from 'feature/common/components/AppContext';
import { AppJSONData } from 'feature/common/types/App';

import { PDCPluginExtensionDataSourceConfigContext } from '../types';

import { DataSourceExtensionContext } from './DataSourceExtensionContext';

export function DataSourceExtensionContextProvider({
  children,
  context,
}: {
  children: JSX.Element;
  context: PDCPluginExtensionDataSourceConfigContext;
}): JSX.Element {
  const { meta } = usePluginContext();

  const jsonData = meta.jsonData as AppJSONData;

  return (
    <DataSourceExtensionContext.Provider
      value={{
        dataSource: context.dataSource,
      }}
    >
      {<AppContext.Provider value={jsonData}>{children}</AppContext.Provider>}
    </DataSourceExtensionContext.Provider>
  );
}
