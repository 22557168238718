import { css } from '@emotion/css';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { GrafanaTheme2 } from '@grafana/data';
import { Alert, LoadingPlaceholder, useStyles2 } from '@grafana/ui';

import { GenerateToken, RealmTypes } from '@grafana-cloud/access-policies';
import { QueryError } from '@grafana-cloud/grafana-com-api';
import { AppContext, useRudderStack } from 'feature/common';
import { generateTokenCacheKey } from 'feature/common/utils/cache';
import { usePrivateNetwork } from 'feature/private-networks/hooks/usePrivateNetwork';
import { PDC_SIGNING_SCOPE, getDefaultTokenName } from 'feature/private-networks/utils';

import { useGetInstanceQuery } from '../api/InstanceApi';

export const CreateTokenForm = ({
  successMessage,
  onClose,
  trackingLocation,
}: {
  successMessage?: string;
  onClose?: () => void;
  trackingLocation: string;
}) => {
  const styles = useStyles2(getStyles);
  const { trackRudderStackEvent } = useRudderStack();

  function onTokenGenerated() {
    trackRudderStackEvent('grafana_pdc_plugin_configuration_token_created', { location: trackingLocation });
  }

  function onClipboardCopy() {
    trackRudderStackEvent('grafana_pdc_plugin_configuration_clipboard_copied', {
      clipboard_name: trackingLocation,
    });
  }
  const { data: instanceData, error, isLoading } = useGetInstanceQuery();

  const { stackId } = useContext(AppContext);
  const { id: privateNetworkId = '' } = useParams<{ id: string }>();
  const { data } = usePrivateNetwork(privateNetworkId);

  if (isLoading) {
    return <LoadingPlaceholder text="Loading..." />;
  }

  if (error) {
    return (
      <Alert title="error" severity="error">
        {(error as QueryError).data?.message}
      </Alert>
    );
  }

  return (
    <>
      <p className={styles.title}>
        Click below to generate a PDC signing token. The token created here allows the private data source connect agent
        to authenticate with your Grafana Cloud instance.
      </p>
      <GenerateToken
        onClose={onClose}
        onTokenGenerated={onTokenGenerated}
        fixedCacheKey={generateTokenCacheKey(privateNetworkId)}
        defaultTokenName={getDefaultTokenName()}
        onClipboardCopy={onClipboardCopy}
        successMessage={successMessage}
        accessPolicyName={data?.name || `pdc-${instanceData?.slug}-default`}
        scopes={[PDC_SIGNING_SCOPE]}
        realms={[
          {
            type: RealmTypes.STACK,
            identifier: stackId,
            labelPolicies: [],
          },
        ]}
      />
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  title: css({
    fontSize: theme.typography.body.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
  }),
});
