import { css } from '@emotion/css';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Icon, useStyles2 } from '@grafana/ui';

import { DeleteToken, Token, TokensList, useGenerateTokenMutation } from '@grafana-cloud/access-policies';
import { AppContext } from 'feature/common';
import { TextLink } from 'feature/common/components/TextLink';
import { generateTokenCacheKey } from 'feature/common/utils/cache';
import { PrivateNetwork } from 'feature/private-networks/types';
import { lookupGcomUrl, privateNetworkToAccessPolicy } from 'feature/private-networks/utils';

import { TokensCreate } from './TokensCreate';

export const Tokens = ({ privateNetwork }: { privateNetwork: PrivateNetwork }) => {
  const styles = useStyles2(getStyles);
  const [deleteToken, setDeleteToken] = useState<Token | undefined>(undefined);
  const [createToken, setCreateToken] = useState<boolean>(false);

  const { id: privateNetworkId = '' } = useParams<{ id: string }>();
  const [_, { reset: resetToken }] = useGenerateTokenMutation({
    fixedCacheKey: generateTokenCacheKey(privateNetworkId),
  });

  const { region, stackId } = useContext(AppContext);

  const onCreateTokenClose = () => {
    setCreateToken(false);
  };

  const onCreateTokenOpen = () => {
    // When opening the modal, remove the cached token
    resetToken();
    setCreateToken(true);
  };

  const { environment } = useContext(AppContext);
  const gcomUrl = lookupGcomUrl(environment);

  return (
    <section className={styles.section}>
      <h2>Tokens</h2>

      <p>
        Tokens created here allow the private data source connect agent to authenticate with your Grafana Cloud
        instance. Manage all access policies on the{' '}
        <TextLink href={gcomUrl + '/profile/org/access-policies'}>Access Policies page</TextLink>. Note that only tokens
        generated here have the required scopes to configure the private data source connect agent.
      </p>
      <div className={styles.tokenTable}>
        <TokensList
          accessPolicy={privateNetworkToAccessPolicy(privateNetwork, stackId, region)}
          onDeleteToken={(token) => setDeleteToken(token)}
        />
      </div>

      <Button variant="secondary" onClick={onCreateTokenOpen}>
        <Icon className={styles.icon} name="plus" size="lg" />
        Add token
      </Button>
      {deleteToken && <DeleteToken region={region} token={deleteToken} onClose={() => setDeleteToken(undefined)} />}
      <TokensCreate isOpen={createToken} onClose={onCreateTokenClose} />
    </section>
  );
};

const getStyles = () => ({
  section: css({
    marginBottom: '3rem',
  }),
  icon: css({
    marginRight: '1rem',
  }),
  tokenTable: css({
    '*': {
      fontSize: '1rem',
    },
    th: {
      fontWeight: '500',
    },
    marginBottom: '2rem',
    marginLeft: '-7px',
  }),
});
