import React from 'react';

import { ConfigSection } from '@grafana/experimental';

import { isAdmin } from 'feature/common/utils/user';
import { DataSourceExtensionFieldSelect } from 'feature/datasource-config/components/DataSourceExtensionFieldSelect';

import { DataSourceExtensionConfigLink } from './DataSourceExtensionConfigLink';
import { Divider } from './Divider';

export const DataSourceExtensionField = ({
  onChange,
  value,
  onNew,
  onClear,
  isLoading,
  newFormStyling,
}: {
  value: string | undefined | null;
  onChange: (value: string | undefined) => void;
  onNew: () => void;
  onClear: () => void;
  isLoading: boolean;
  newFormStyling: boolean;
}) => (
  <DatasourceExtensionSelectWrapper newFormStyling={newFormStyling}>
    <>
      <DataSourceExtensionFieldSelect
        onChange={onChange}
        value={value}
        onNew={onNew}
        onClear={onClear}
        isLoading={isLoading}
        newFormStyling={newFormStyling}
      />
      {isAdmin() && <DataSourceExtensionConfigLink />}
    </>
  </DatasourceExtensionSelectWrapper>
);

const DatasourceExtensionSelectWrapper = ({
  newFormStyling,
  children,
}: {
  newFormStyling: boolean;
  children: JSX.Element;
}) =>
  newFormStyling ? (
    <div className="width-30">
      <Divider />
      <ConfigSection title="Private data source connect">{children}</ConfigSection>
    </div>
  ) : (
    <>
      <h6 className="page-heading">Private data source connect</h6>
      <div className="gf-form-group" aria-label="Private data source connect settings">
        {children}
      </div>
    </>
  );
