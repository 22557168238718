import { useEffect } from 'react';

import { BusEventBase } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';

import { useRudderStack } from 'feature/common/hooks/useRudderStack';

export const useDataSourceTest = (value: string | null | undefined, dataSourceType: string, dataSourceUID: string) => {
  const { trackRudderStackEvent } = useRudderStack();
  useEffect(() => {
    if (!value) {
      return;
    }
    // TODO: import this interface from Grafana once it is released
    class DataSourceTestSucceeded extends BusEventBase {
      static type = 'datasource-test-succeeded';
    }
    // TODO: import this interface from Grafana once it is released
    class DataSourceTestFailed extends BusEventBase {
      static type = 'datasource-test-failed';
    }
    const appEvents = getAppEvents();
    const eventData = {
      connection_id: value,
      data_source_type: dataSourceType,
      data_source_uid: dataSourceUID,
    };

    const dataSourceTestSucceededSubscription = appEvents.subscribe(DataSourceTestSucceeded, () => {
      trackRudderStackEvent('grafana_pdc_plugin_data_source_extension_data_source_test_success', eventData);
    });
    const dataSourceTestFailedSubscription = appEvents.subscribe(DataSourceTestFailed, () => {
      trackRudderStackEvent('grafana_pdc_plugin_data_source_extension_data_source_test_failure', eventData);
    });
    return () => {
      dataSourceTestSucceededSubscription.unsubscribe();
      dataSourceTestFailedSubscription.unsubscribe();
    };
  }, [dataSourceType, dataSourceUID, trackRudderStackEvent, value]);
};
