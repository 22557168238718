import { css } from '@emotion/css';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { useGenerateTokenMutation } from '@grafana-cloud/access-policies';
import { Clipboard } from '@grafana-cloud/ui';
import { AppContext, useAppSelector, useRudderStack } from 'feature/common';
import { TextLink } from 'feature/common/components/TextLink';
import { generateTokenCacheKey } from 'feature/common/utils/cache';
import { usePrivateNetwork } from 'feature/private-networks/hooks/usePrivateNetwork';
import { getPrivateNetworkDetailState } from 'feature/private-networks/state/privateNetworkDetailSlice';
import { generateBinaryInstruction, getHighlightLinesForSnippet, lookupDomain } from 'feature/private-networks/utils';

export const ConfigurationInstructionsDeploymentBinary = () => {
  const styles = useStyles2(getStyles);
  const { trackRudderStackEvent } = useRudderStack();
  const { id: privateNetworkId = '' } = useParams<{ id: string }>();

  const { tokenType, existingPdcToken, shouldHighlightLines } = useAppSelector((state) =>
    getPrivateNetworkDetailState(state, privateNetworkId)
  );

  const { stackId, pdcClusterSlug, environment } = useContext(AppContext);

  const [_, { data: pdcToken }] = useGenerateTokenMutation({
    fixedCacheKey: generateTokenCacheKey(privateNetworkId),
  });

  const { data: privateNetworkData } = usePrivateNetwork(privateNetworkId);

  const domain = lookupDomain(environment);
  const instruction = generateBinaryInstruction(
    pdcClusterSlug,
    stackId,
    privateNetworkData?.allowedHosts || [],
    tokenType,
    pdcToken,
    domain,
    existingPdcToken
  );

  const handleOnClipboardCopy = (trackingName: string) => () => {
    trackRudderStackEvent('grafana_pdc_plugin_configuration_clipboard_copied', {
      clipboard_name: trackingName,
    });
  };

  const [linesToHighlight, setLinesToHighlight] = useState<number[] | undefined>([]);

  useEffect(() => {
    setLinesToHighlight(getHighlightLinesForSnippet(instruction, '-token'));
  }, [instruction]);

  return (
    <>
      <p className={styles.subTitle}>Using a pdc-agent binary</p>
      <p>
        Download and unzip the binary for your OS from the{' '}
        <TextLink href="https://github.com/grafana/pdc-agent/releases/latest">PDC Agent releases page</TextLink>.
      </p>
      <p>Run the binary:</p>
      <Clipboard
        multipleLines={true}
        code={instruction}
        onClipboardCopy={handleOnClipboardCopy('binary_instructions')}
        highlightLines={shouldHighlightLines ? linesToHighlight : undefined}
      />
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  subTitle: css({
    fontWeight: theme.typography.fontWeightBold,
  }),
});
