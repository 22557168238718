import { queryDatasource } from '@grafana-cloud/collector';

import { AppApi } from '../../common/api/AppApi';

const connectedAgentsApi = AppApi.injectEndpoints({
  endpoints: (build) => ({
    hadConnectedAgents: build.mutation<boolean, void>({
      queryFn: async (args, _queryApi, _extraOptions, baseQuery) => {
        const queryParam = `grafanacloud_grafana_pdc_connected_agents`;
        const result = await queryDatasource('grafanacloud-usage', queryParam);
        if (result.length) {
          return {
            data: true,
          };
        } else {
          return {
            data: false,
          };
        }
      },
    }),
  }),
});

export const { useHadConnectedAgentsMutation } = connectedAgentsApi;
