import { format, isBefore, parseISO } from 'date-fns';

import { dateTimeFormat } from '@grafana/data';
import { TimeZone } from '@grafana/schema';

export function dateStrToMs(dateStr: string): number {
  return new Date(dateStr).getTime();
}

export const dateStringToISOString = (value: string) => {
  if (!!value) {
    const date = new Date(value);
    const now = new Date();
    date.setUTCHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
    return date.toISOString();
  }
  return '';
};

export const validateDate = (value: string | undefined) => {
  if (!value || isBefore(new Date(Date.now()), parseISO(value.toString()))) {
    return true;
  }
  return 'Date cannot be in the past';
};

export const getNowAsString = () => format(new Date(Date.now()), 'yyyy-MM-dd');

export const daysLeftUntilExpiration = (secondsUntilExpiration: number) => {
  const days = Math.ceil(secondsUntilExpiration / (3600 * 24));
  const daysFormat = days > 1 ? `${days} days` : `${days} day`;
  return `Expires in ${daysFormat}`;
};

export const formatDate = (timeZone: TimeZone, expiration?: string) => {
  if (!expiration) {
    return 'No expiration date';
  }
  return dateTimeFormat(expiration, { timeZone });
};
