import { css } from '@emotion/css';
import React, { useContext, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { GrafanaTheme2 } from '@grafana/data';
import { PluginPage } from '@grafana/runtime';
import { Alert, Button, Icon, LoadingPlaceholder, useStyles2 } from '@grafana/ui';

import { QueryError } from '@grafana-cloud/grafana-com-api';
import { TabsPanel } from '@grafana-cloud/ui';
import { PDC_DOCS_URL, PRIVATE_NETWORKS_PATH } from 'consts';
import { AppContext, TextLink, useGetInstanceQuery } from 'feature/common';
import { useRudderStack } from 'feature/common/hooks';
import { useGetDataSourcesQuery } from 'feature/private-networks/api/DataSourceApi';
import { usePrivateNetwork } from 'feature/private-networks/hooks/usePrivateNetwork';
import { useTokens } from 'feature/private-networks/hooks/useTokens';
import { PrivateNetwork } from 'feature/private-networks/types/PrivateNetwork';
import { getDefaultAccessPolicyName } from 'feature/private-networks/utils/accessPolicies';

import { DeletePrivateNetworkModal } from '../PrivateNetworkList/DeletePrivateNetworkModal';

import { ConfigurationTab } from './ConfigurationTab';
import { OverviewTab } from './OverviewTab';
import { Tabs } from './Tabs';

export const PrivateNetworkDetail = () => {
  const styles = useStyles2(getStyles);
  const { region } = useContext(AppContext);
  const { id: privateNetworkId = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [deletePrivateNetwork, setDeletePrivateNetwork] = useState<PrivateNetwork | undefined>();

  // preload instance data so we load it in parallel
  const { data: instanceData } = useGetInstanceQuery();

  const { trackRudderStackEvent } = useRudderStack();

  const {
    data: privateNetworkData,
    isError: privateNetworkIsError,
    error: privateNetworkError,
    isFetching: privateNetworkIsFetching,
  } = usePrivateNetwork(privateNetworkId);
  const { isLoading: tokensIsLoading } = useTokens();
  const {
    data: dataSourcesData,
    isError: dataSourcesIsError,
    error: dataSourcesError,
    isLoading: dataSourcesIsLoading,
  } = useGetDataSourcesQuery({
    region,
  });
  const dataSources = dataSourcesData?.dataSourcesByPrivateNetwork[privateNetworkId] ?? [];

  const renderTitle = () => {
    return (
      <div className={styles.title}>
        <Icon name="lock" size="xl" />
        <h1 className={styles.mainTitle}>{privateNetworkData?.displayName}</h1>
      </div>
    );
  };

  const subTitle = (
    <>
      Query data that lives within a secured network without opening the network to inbound traffic from Grafana Cloud.
      Learn more in our{' '}
      <TextLink
        href={PDC_DOCS_URL}
        onClick={() =>
          trackRudderStackEvent('grafana_pdc_plugin_documentation_link_clicked', {
            location: 'private_network_detail_page_subtitle',
          })
        }
      >
        docs
      </TextLink>
      .
    </>
  );

  const pageNav = useMemo(
    () => ({ text: privateNetworkData?.displayName ?? privateNetworkId }),
    [privateNetworkData, privateNetworkId]
  );

  const isDefaultConnection =
    instanceData && privateNetworkData?.name === getDefaultAccessPolicyName(instanceData.slug);

  if (privateNetworkIsFetching || dataSourcesIsLoading || tokensIsLoading) {
    return (
      <PluginPage>
        <LoadingPlaceholder text="Loading ..." />
      </PluginPage>
    );
  }

  if (privateNetworkIsError || dataSourcesIsError) {
    return (
      <PluginPage pageNav={pageNav}>
        <Alert title="Error when loading private data source connect">
          {((privateNetworkError || dataSourcesError) as QueryError).data?.message}
        </Alert>
      </PluginPage>
    );
  }

  if (!privateNetworkData) {
    return (
      <PluginPage pageNav={pageNav}>
        <Alert title="Not found">Private data source connect &quot;{privateNetworkId}&quot; not found</Alert>
      </PluginPage>
    );
  }

  return (
    <PluginPage
      pageNav={pageNav}
      renderTitle={renderTitle}
      subTitle={subTitle}
      actions={
        <Button
          variant="destructive"
          disabled={isDefaultConnection}
          onClick={() => setDeletePrivateNetwork(privateNetworkData)}
          tooltip={isDefaultConnection ? 'You cannot delete the default connection' : undefined}
        >
          Delete
        </Button>
      }
    >
      {deletePrivateNetwork && (
        <DeletePrivateNetworkModal
          privateNetwork={deletePrivateNetwork}
          onClose={() => setDeletePrivateNetwork(undefined)}
          onDelete={() => navigate(PRIVATE_NETWORKS_PATH)}
        />
      )}
      <TabsPanel
        tabs={[
          {
            id: Tabs.Overview,
            label: 'Overview',
            component: <OverviewTab privateNetwork={privateNetworkData} dataSources={dataSources}></OverviewTab>,
            active: true,
          },
          {
            id: Tabs.ConfigurationDetails,
            label: 'Configuration Details',
            component: (
              <ConfigurationTab privateNetwork={privateNetworkData} dataSources={dataSources}></ConfigurationTab>
            ),
          },
        ]}
      ></TabsPanel>
    </PluginPage>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  title: css({
    display: 'flex',
    alignItems: 'center',
  }),
  mainTitle: css({
    marginBottom: 0,
    marginLeft: theme.spacing(1),
  }),
});
