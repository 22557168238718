import { DataSource } from 'feature/common';
import { ConfigurationTab, OverviewTab } from 'feature/private-networks/components';
import { PrivateNetwork } from 'feature/private-networks/types';

export enum Tabs {
  Overview = 'overview',
  ConfigurationDetails = 'configuration_details',
}

export type TabProps = {
  privateNetwork: PrivateNetwork;
  dataSources: DataSource[];
};

export type TabsType = {
  id: Tabs;
  label: string;
  component: React.FC<TabProps>;
  isVisible: boolean;
  isDefault?: boolean;
};

export const tabs: TabsType[] = [
  {
    id: Tabs.Overview,
    label: 'Overview',
    component: OverviewTab,
    isVisible: true,
  },
  {
    id: Tabs.ConfigurationDetails,
    label: 'Configuration Details',
    component: ConfigurationTab,
    isVisible: true,
    isDefault: true,
  },
];
