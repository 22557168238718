import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppState } from 'feature/common';
import { TokenType } from 'feature/common/types';
import { InstallMethod } from 'feature/private-networks/types';

type PrivateNetworkDetailState = {
  tokenType: TokenType;
  installationOptionValue: InstallMethod;
  existingPdcToken: string;
  shouldHighlightLines: boolean;
};

type PrivateNetworkDetailsState = { [id: string]: PrivateNetworkDetailState };

const defaultNetworkState: PrivateNetworkDetailState = {
  tokenType: TokenType.NewToken,
  installationOptionValue: InstallMethod.KUBERNETES,
  existingPdcToken: '',
  shouldHighlightLines: false,
};

const initialState: PrivateNetworkDetailsState = {};

function setState(
  id: string,
  state: PrivateNetworkDetailsState,
  setter: (detailState: PrivateNetworkDetailState) => void
): void {
  const detailState = state[id] || { ...defaultNetworkState };
  setter(detailState);
  state[id] = detailState;
}

export const privateNetworkDetailSlice = createSlice({
  name: 'privateNetworkDetail',
  initialState,
  reducers: {
    setTokenType: (
      state,
      action: PayloadAction<{
        tokenType: TokenType;
        id: string;
      }>
    ) => {
      setState(action.payload.id, state, (detailState) => {
        detailState.tokenType = action.payload.tokenType;
      });
    },
    setInstallationOptionValue: (
      state,
      action: PayloadAction<{
        installationOptionValue: InstallMethod;
        id: string;
      }>
    ) => {
      setState(action.payload.id, state, (detailState) => {
        detailState.installationOptionValue = action.payload.installationOptionValue;
      });
    },
    setExistingPdcToken: (
      state,
      action: PayloadAction<{
        existingPdcToken: string;
        id: string;
      }>
    ) => {
      setState(action.payload.id, state, (detailState) => {
        detailState.existingPdcToken = action.payload.existingPdcToken;
      });
    },
    setShouldHighlightLines: (
      state,
      action: PayloadAction<{
        shouldHighlightLines: boolean;
        id: string;
      }>
    ) => {
      setState(action.payload.id, state, (detailState) => {
        detailState.shouldHighlightLines = action.payload.shouldHighlightLines;
      });
    },
  },
});

export function getPrivateNetworkDetailState(state: AppState, privateNetworkId: string): PrivateNetworkDetailState {
  return state.privateNetworkDetail[privateNetworkId] || defaultNetworkState;
}

export const { setTokenType, setInstallationOptionValue, setExistingPdcToken, setShouldHighlightLines } =
  privateNetworkDetailSlice.actions;
