import { useContext } from 'react';

import { RealmTypes, useGetTokensQuery } from '@grafana-cloud/access-policies';
import { AppContext } from 'feature/common';

export function useTokens() {
  const { region, stackId } = useContext(AppContext);
  return useGetTokensQuery({
    region: region,
    accessPolicyRealmType: RealmTypes.STACK,
    accessPolicyRealmIdentifier: stackId,
  });
}
