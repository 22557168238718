import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, useStyles2 } from '@grafana/ui';

import { PDC_DOCS_URL } from 'consts';
import { TextLink } from 'feature/common/components/TextLink';
import { useRudderStack } from 'feature/common/hooks';

export const ConfigurationQuestionSection = () => {
  const styles = useStyles2(getStyles);
  const { trackRudderStackEvent } = useRudderStack();

  return (
    <>
      <h4 className={styles.linksList}>Looking for extra help?</h4>
      <TextLink
        href={PDC_DOCS_URL}
        onClick={() =>
          trackRudderStackEvent('grafana_pdc_plugin_documentation_link_clicked', {
            location: 'configuration_question_section',
          })
        }
      >
        <Icon name="arrow-right" className={styles.iconColor} />
        Configure Grafana private data source connect
      </TextLink>
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  iconColor: css({
    color: theme.colors.text.secondary,
    marginRight: 4,
  }),
  buttonWrapper: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  }),
  linksList: css({
    marginTop: theme.spacing(2),
  }),
});
