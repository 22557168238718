import * as React from 'react';
import { Provider, useStore } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { AppRootProps } from '@grafana/data';

import { AccessPoliciesProvider, RealmTypes } from '@grafana-cloud/access-policies';
import { GrafanaComApiProvider } from '@grafana-cloud/grafana-com-api';
import { AppApiContext, GCOM_URL } from 'feature/common/api';
import { AppContext, setGrafanaStore } from 'feature/common/components/AppContext';
import { useRudderStack } from 'feature/common/hooks';
import { store } from 'feature/common/store';
import { PDCTokensTableColumns } from 'feature/private-networks/components/PrivateNetworkDetail/OverviewTab/Tokens/PDCTokensTableColumns';
import { PrivateNetworkDetail } from 'feature/private-networks/components/PrivateNetworkDetail/PrivateNetworkDetail';
import { PrivateNetworks } from 'feature/private-networks/components/PrivateNetworkList/PrivateNetworks';

import { AppJSONData } from '../types/App';

export function App(props: AppRootProps<AppJSONData>): JSX.Element {
  const stackId = props.meta?.jsonData?.['stackId'] ?? '';
  const region = props.meta?.jsonData?.['region'] ?? '';
  const pdcClusterSlug = props.meta?.jsonData?.['pdcClusterSlug'] ?? '';
  const environment = props.meta?.jsonData?.['environment'] ?? '';
  const { trackRudderStackEvent } = useRudderStack();
  const grafanaStore = useStore();

  // setting grafana store as a global var scoped in the AppContext so
  // that we can access it without hooks in APIs
  setGrafanaStore(grafanaStore);

  return (
    <AppContext.Provider
      value={{
        stackId,
        region,
        pdcClusterSlug,
        environment,
      }}
    >
      <Provider store={store} context={AppApiContext}>
        <GrafanaComApiProvider config={{ baseApiUrl: GCOM_URL }}>
          <AccessPoliciesProvider
            config={{
              realm: { realmType: RealmTypes.STACK, realmIdentifier: String(stackId) },
              region,
              track: trackRudderStackEvent,
              components: {
                TokensTableColumns: PDCTokensTableColumns,
              },
            }}
          >
            <Routes>
              <Route path=":id" element={<PrivateNetworkDetail />} />
              <Route path="" element={<PrivateNetworks />} />
            </Routes>
          </AccessPoliciesProvider>
        </GrafanaComApiProvider>
      </Provider>
    </AppContext.Provider>
  );
}
