import { css } from '@emotion/css';
import React, { useContext } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Alert, ConfirmModal, useStyles2 } from '@grafana/ui';

import { QueryError } from '@grafana-cloud/grafana-com-api';
import { useRudderStack } from 'feature/common';
import { AppContext } from 'feature/common/components/AppContext';
import { useGetDataSourcesQuery } from 'feature/private-networks/api/DataSourceApi';
import { useDeletePrivateNetworkMutation } from 'feature/private-networks/api/PrivateNetworksApi';
import { DataSourcesList } from 'feature/private-networks/components/PrivateNetworkDetail/OverviewTab/DataSources/DataSourcesList';
import { PrivateNetwork } from 'feature/private-networks/types/PrivateNetwork';

type Props = {
  onClose: () => void;
  onDelete?: () => void;
  privateNetwork: PrivateNetwork;
};

export const DeletePrivateNetworkModal = ({ onClose, onDelete, privateNetwork }: Props) => {
  const styles = useStyles2(getStyles);
  const { trackRudderStackEvent } = useRudderStack();

  const [mutate, { error, isError, isLoading }] = useDeletePrivateNetworkMutation();
  const { region, stackId } = useContext(AppContext);

  const { data: dataSourcesData } = useGetDataSourcesQuery({
    region,
  });

  const dataSourcesLength = dataSourcesData?.dataSourcesByPrivateNetwork?.[privateNetwork.id]?.length;
  const dataSources = dataSourcesData?.dataSourcesByPrivateNetwork[privateNetwork.id] ?? [];

  async function deletePrivateNetwork(privateNetwork: PrivateNetwork) {
    mutate({ privateNetwork, region, stackId })
      .unwrap()
      .then(() => {
        if (onDelete) {
          onDelete();
        }
        onClose();
      });
  }

  const errorAlert = () => {
    return (
      <Alert severity="error" title="Error when deleting private network">
        {(error as QueryError)?.data.message}
      </Alert>
    );
  };

  const onDeleteHandler = (privateNetwork: PrivateNetwork) => {
    trackRudderStackEvent('grafana_pdc_plugin_list_delete_connection', {
      connection_id: privateNetwork.id,
    });
    deletePrivateNetwork(privateNetwork);
  };

  const renderDeleteModalBody = () => {
    if (isError) {
      errorAlert();
    }
    return (
      <>
        <span>Are you sure you want to delete this connection?</span>
        {!!dataSourcesLength && dataSourcesLength > 0 && (
          <>
            <span> The following data source{dataSourcesLength !== 1 ? 's' : ''} will be disconnected:</span>
            <div className={styles.dataSources}>
              <DataSourcesList dataSources={dataSources} />
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <ConfirmModal
      isOpen
      title="Delete connection"
      body={renderDeleteModalBody()}
      confirmText={isLoading ? 'Deleting...' : 'Delete'}
      onConfirm={() => onDeleteHandler(privateNetwork)}
      onDismiss={onClose}
    />
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  dataSources: css({
    marginTop: theme.spacing(3),
    maxHeight: '500px',
    overflow: 'scroll',
  }),
});
