import { css } from '@emotion/css';
import React, { useContext } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Field, Form, Input, Modal, Spinner, useStyles2 } from '@grafana/ui';

import { QueryError } from '@grafana-cloud/grafana-com-api';
import { Alert } from '@grafana-cloud/ui';
import { AppContext, useRudderStack, validateConnectionName } from 'feature/common';
import {
  useGetPrivateNetworksQuery,
  useUpdatePrivateNetworkMutation,
} from 'feature/private-networks/api/PrivateNetworksApi';
import { PrivateNetwork } from 'feature/private-networks/types';

type Props = {
  privateNetwork?: PrivateNetwork;
  onClose: () => void;
};

export const EditPrivateNetworkModal = ({ privateNetwork, onClose }: Props) => {
  const styles = useStyles2(getStyles);
  const { trackRudderStackEvent } = useRudderStack();

  const { region, stackId } = useContext(AppContext);
  const [updatePrivateNetwork, { error, isError, isLoading }] = useUpdatePrivateNetworkMutation();

  const { data } = useGetPrivateNetworksQuery({ region, stackId });
  const existingNames = data ? data.map((item: PrivateNetwork) => item.name) : [];

  const editPrivateNetworks = (privateNetwork: PrivateNetwork) => {
    updatePrivateNetwork({
      privateNetwork,
      region,
      stackId,
    })
      .unwrap()
      .then((editedNetwork) => {
        trackRudderStackEvent('grafana_pdc_plugin_list_edit_connection', {
          connection_id: editedNetwork.id,
        });
        onClose();
      });
  };

  if (!privateNetwork) {
    return <></>;
  }

  return (
    <Modal
      isOpen={!!privateNetwork}
      onClickBackdrop={onClose}
      onDismiss={onClose}
      title="Update name of private data source connect"
    >
      <Form
        validateOn="onChange"
        onSubmit={(formData: { connectionName: string }) => {
          editPrivateNetworks({ ...privateNetwork, displayName: formData.connectionName });
        }}
      >
        {({ register, errors, watch }) => {
          return (
            <>
              <Field label="Name" invalid={!!errors.connectionName} error={errors?.connectionName?.message}>
                <Input
                  type="text"
                  id="connectionName"
                  aria-label="Connection name"
                  placeholder="Connection name"
                  defaultValue={privateNetwork.displayName}
                  {...register('connectionName', {
                    validate: (value) => validateConnectionName(value, existingNames),
                    required: { value: true, message: 'Connection name is required' },
                  })}
                ></Input>
              </Field>
              <div className={styles.modalButtons}>
                <Button
                  type="submit"
                  className={styles.submitBtn}
                  disabled={!watch('connectionName') || !!errors.connectionName || isLoading}
                >
                  {isLoading && <Spinner className={styles.spinner} />}
                  Update
                </Button>
                <Button onClick={onClose} variant="secondary">
                  Cancel
                </Button>
              </div>
              {isError && (
                <Alert status="error">
                  Error when updating name of private data source connect - {(error as QueryError).data?.message}
                </Alert>
              )}
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  submitBtn: css({
    marginRight: theme.spacing(2),
  }),
  modalButtons: css({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  spinner: css({
    marginRight: theme.spacing(1),
  }),
});
