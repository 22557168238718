import React, { ReactElement, useContext } from 'react';

import { SelectableValue } from '@grafana/data';
import { Field, InlineField, Select } from '@grafana/ui';

import { AppContext } from 'feature/common';
import { canEditDataSource, isAdmin } from 'feature/common/utils/user';
import { isValueInOptions } from 'feature/datasource-config/utils';
import { useGetConnectionStatusQuery } from 'feature/private-networks/api/ConnectionStatusApi';
import { useGetPrivateNetworksQuery } from 'feature/private-networks/api/PrivateNetworksApi';
import { getConnectionStatus, getConnectionStatusText } from 'feature/private-networks/utils/privateNetwork';

import { DataSourceExtensionContext } from './DataSourceExtensionContext';

type Props = {
  onNew: () => void;
  onClear: () => void;
  onChange: (value: string | undefined) => void;
  value: string | undefined | null;
  isLoading: boolean;
  newFormStyling: boolean;
};

export const DataSourceExtensionFieldSelect = ({
  onNew,
  onClear,
  value,
  onChange,
  isLoading,
  newFormStyling,
}: Props) => {
  const { region, stackId } = useContext(AppContext);
  const { data, isLoading: isNetworksLoading } = useGetPrivateNetworksQuery({
    region,
    stackId,
  });

  const { data: connectionInfoAll, isFetching, isError } = useGetConnectionStatusQuery();

  const connectionInfoById = connectionInfoAll?.byId;

  const options: Array<{ label: string; value: string; icon: string }> | undefined = data?.map(
    (privateNetwork, idx) => {
      const connectionInfo = connectionInfoById && connectionInfoById[privateNetwork.id];

      const connectionStatus = getConnectionStatus(connectionInfo?.[0], isFetching, isError);
      const numConnectedAgents = connectionInfo?.[0]?.numConnectedAgents ?? -1;

      const connectionStatusText = getConnectionStatusText(connectionStatus, numConnectedAgents);

      return {
        label: `${privateNetwork.displayName} (${connectionStatusText})`,
        value: privateNetwork.id,
        icon: 'lock',
      };
    }
  );

  const createNewValue = 'create-new';

  if (isAdmin()) {
    options?.unshift({
      label: 'Create new...',
      value: createNewValue,
      icon: 'plus',
    });
  }

  const onValueChange = (v: SelectableValue<string>) => {
    if (v === null) {
      return onClear();
    } else if (v.value === createNewValue) {
      return onNew();
    } else {
      onChange(v.value);
    }
  };

  return (
    <SelectWrapper newFormStyling={newFormStyling}>
      <Select
        width={newFormStyling ? undefined : 40}
        aria-label={`Private data source connect`}
        options={options}
        value={value}
        isClearable={true}
        loadingMessage="Loading..."
        isLoading={isLoading || isNetworksLoading}
        onChange={onValueChange}
        placeholder={
          value === undefined || value === null || isValueInOptions(value, options)
            ? undefined
            : 'Private data source connect not found'
        }
      />
    </SelectWrapper>
  );
};

const SelectWrapper = ({ newFormStyling, children }: { newFormStyling: boolean; children: ReactElement }) => {
  const { dataSource } = useContext(DataSourceExtensionContext);

  return newFormStyling ? (
    <Field
      label="Private data source connect"
      disabled={!canEditDataSource(dataSource)}
      description="Query data that lives within a secured network without opening the network to inbound traffic from Grafana Cloud."
    >
      {children}
    </Field>
  ) : (
    <div className="gf-form">
      <InlineField
        label="Private data source connect"
        labelWidth={30}
        disabled={!canEditDataSource(dataSource)}
        tooltip="Query data that lives within a secured network without opening the network to inbound traffic from Grafana Cloud."
        interactive={true}
      >
        {children}
      </InlineField>
    </div>
  );
};
