import { css } from '@emotion/css';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { useGenerateTokenMutation } from '@grafana-cloud/access-policies';
import { Clipboard } from '@grafana-cloud/ui';
import { AppContext, useAppSelector, useRudderStack } from 'feature/common';
import { TextLink } from 'feature/common/components/TextLink';
import { generateTokenCacheKey } from 'feature/common/utils/cache';
import { usePrivateNetwork } from 'feature/private-networks/hooks/usePrivateNetwork';
import { getPrivateNetworkDetailState } from 'feature/private-networks/state/privateNetworkDetailSlice';
import {
  generateKubernetesInstruction,
  getHighlightLinesForSnippet,
  lookupDomain,
} from 'feature/private-networks/utils';

export const ConfigurationInstructionsDeploymentKubernetes = () => {
  const styles = useStyles2(getStyles);
  const { trackRudderStackEvent } = useRudderStack();
  const { id: privateNetworkId = '' } = useParams<{ id: string }>();

  const { tokenType, existingPdcToken, shouldHighlightLines } = useAppSelector((state) =>
    getPrivateNetworkDetailState(state, privateNetworkId)
  );

  const { data: privateNetworkData } = usePrivateNetwork(privateNetworkId);

  const [_, { data: pdcToken }] = useGenerateTokenMutation({
    fixedCacheKey: generateTokenCacheKey(privateNetworkId),
  });
  const { stackId, pdcClusterSlug, environment } = useContext(AppContext);

  const domain = lookupDomain(environment);
  const secretInstructions = generateKubernetesInstruction(
    pdcClusterSlug,
    stackId,
    tokenType,
    pdcToken,
    existingPdcToken
  );

  const handleOnClipboardCopy = (trackingName: string) => () => {
    trackRudderStackEvent('grafana_pdc_plugin_configuration_clipboard_copied', {
      clipboard_name: trackingName,
    });
  };

  const [linesToHighlight, setLinesToHighlight] = useState<number[] | undefined>([]);

  useEffect(() => {
    setLinesToHighlight(getHighlightLinesForSnippet(secretInstructions, 'token'));
  }, [secretInstructions]);

  return (
    <>
      <p className={styles.subTitle}>Using Kubernetes</p>
      <p>Create a Kubernetes secret with the PDC signing token, Hosted Grafana ID and PDC Cluster values:</p>
      <Clipboard
        multipleLines={true}
        code={secretInstructions}
        onClipboardCopy={handleOnClipboardCopy('kubernetes_generate_secret')}
        highlightLines={shouldHighlightLines ? linesToHighlight : undefined}
      />
      <p>
        Generate a Kubernetes deployment to deploy the agent. An example deployment is provided in the{' '}
        <TextLink href="https://github.com/grafana/pdc-agent/blob/main/production/kubernetes/pdc-agent-deployment.yaml">
          pdc-agent repository
        </TextLink>
        :
      </p>
      <Clipboard
        multipleLines={true}
        code={`kubectl apply -f https://raw.githubusercontent.com/grafana/pdc-agent/main/production/kubernetes/pdc-agent-deployment.yaml`}
        onClipboardCopy={handleOnClipboardCopy('kubernetes_generate_deployment')}
      />
      {domain && (
        <Clipboard
          multipleLines={true}
          code={`kubectl patch deployment grafana-pdc-agent --type='json' -p='[{"op": "add", "path": "/spec/template/spec/containers/0/args/-","value":"-domain"},{"op": "add", "path": "/spec/template/spec/containers/0/args/-","value":"${domain}"}]'`}
        />
      )}
      {privateNetworkData && privateNetworkData.allowedHosts && privateNetworkData.allowedHosts.length > 0 && (
        <Clipboard
          multipleLines={true}
          code={`kubectl patch deployment grafana-pdc-agent --type='json' -p='[{"op": "add", "path": "/spec/template/spec/containers/0/args/-","value":"-ssh-flag"},{"op": "add", "path": "/spec/template/spec/containers/0/args/-","value":"-o PermitRemoteOpen=${privateNetworkData.allowedHosts.join(
            ' '
          )}"}]'`}
          onClipboardCopy={handleOnClipboardCopy('kubernetes_limit_hosts')}
        />
      )}
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  subTitle: css({
    fontWeight: theme.typography.fontWeightBold,
  }),
});
