import React, { useContext, useMemo, useState } from 'react';

import { SortType } from 'consts';
import { AppContext } from 'feature/common/components/AppContext';
import { useGetPrivateNetworksQuery } from 'feature/private-networks/api/PrivateNetworksApi';
import { DeletePrivateNetworkModal } from 'feature/private-networks/components/PrivateNetworkList/DeletePrivateNetworkModal';
import { PrivateNetworkListItem } from 'feature/private-networks/components/PrivateNetworkList/PrivateNetworkListItem';
import { PrivateNetwork } from 'feature/private-networks/types';
import { filterAndSortPrivateNetworks } from 'feature/private-networks/utils/privateNetwork';

type Props = {
  filter: string;
  sort: SortType;
};

export const PrivateNetworkList = ({ filter, sort }: Props) => {
  const { region, stackId } = useContext(AppContext);
  const { data: networks } = useGetPrivateNetworksQuery({ region, stackId });

  const filteredData = useMemo(
    () => filterAndSortPrivateNetworks(networks ?? [], filter, sort),
    [networks, filter, sort]
  );

  const [deletePrivateNetwork, setDeletePrivateNetwork] = useState<PrivateNetwork | undefined>();

  return filteredData && filteredData.length > 0 ? (
    <>
      {deletePrivateNetwork && (
        <DeletePrivateNetworkModal
          privateNetwork={deletePrivateNetwork}
          onClose={() => setDeletePrivateNetwork(undefined)}
        />
      )}
      <h2>Networks</h2>
      {filteredData.map((privateNetwork) => (
        <PrivateNetworkListItem
          key={privateNetwork.id}
          onDeleteClick={() => setDeletePrivateNetwork(privateNetwork)}
          privateNetwork={privateNetwork}
        />
      ))}
    </>
  ) : (
    <></>
  );
};
