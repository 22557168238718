import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { IconButton, useStyles2 } from '@grafana/ui';

import {
  Token,
  TokensTableColumnIDs,
  TokensTableColumns,
  TokensTableColumnsType,
} from '@grafana-cloud/access-policies';

export function PDCTokensTableColumns({
  onDeleteToken,
}: {
  onDeleteToken: (token: Token) => void;
}): TokensTableColumnsType {
  const defaultColumns = TokensTableColumns({ onDeleteToken });
  const styles = useStyles2(getStyles);

  return {
    [TokensTableColumnIDs.name]: {
      header: 'Name',
      cell: (token: Token) => <>{token.displayName}</>,
    },
    [TokensTableColumnIDs.expiresAt]: {
      header: 'Expires',
      cell: defaultColumns.expiresAt!.cell,
    },
    [TokensTableColumnIDs.createdAt]: {
      header: 'Created',
      cell: defaultColumns.createdAt!.cell,
    },
    [TokensTableColumnIDs.lastUsedAt]: defaultColumns.lastUsedAt,
    [TokensTableColumnIDs.delete]: {
      header: '',
      cell: (token) => (
        <div className={styles.delete}>
          <IconButton name="trash-alt" aria-label="Remove" onClick={() => onDeleteToken(token)} />
        </div>
      ),
    },
  };
}

const getStyles = (theme: GrafanaTheme2) => ({
  delete: css({
    width: '100%',
    textAlign: 'right',
  }),
});
