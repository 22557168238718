import { Dictionary } from '@reduxjs/toolkit';
import groupBy from 'lodash/groupBy';

import { AppApi, PDC_API_URL } from 'feature/common/api/AppApi';

export interface ConnectionInfo {
  network: string;
  numConnectedAgents: number;
}

interface ConnectionStatusResult {
  items: ConnectionInfo[];
  byId: Dictionary<ConnectionInfo[]>;
}

const url = PDC_API_URL + 'agent-connection-status';
const method = 'POST';
export const CONNECTION_STATUS_TAG_TYPE = 'CONNECTION_STATUS';

export const ConnectionStatusApi = AppApi.enhanceEndpoints({
  addTagTypes: [CONNECTION_STATUS_TAG_TYPE],
}).injectEndpoints({
  endpoints: (build) => ({
    getConnectionStatus: build.query<ConnectionStatusResult, void>({
      query() {
        return {
          url,
          body: {
            networks: [],
          },
          method,
        };
      },
      transformResponse: (response: ConnectionInfo[]) => ({
        items: response,
        byId: groupBy(response, (item) => item.network),
      }),
      providesTags: [CONNECTION_STATUS_TAG_TYPE],
    }),
  }),
});

export const { useGetConnectionStatusQuery } = ConnectionStatusApi;
