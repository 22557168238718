import { Store } from '@reduxjs/toolkit';
import { createContext } from 'react';

import { AppJSONData } from '../types/App';

interface AppContextData extends AppJSONData {}

let grafanaStore: Store | null = null;
export const AppContext = createContext<AppContextData>({
  stackId: '',
  region: '',
  pdcClusterSlug: '',
  environment: 'dev',
});

export function setGrafanaStore(store: Store) {
  grafanaStore = store;
}

export function getGrafanaStore(): Store | null {
  return grafanaStore;
}
