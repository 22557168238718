export const validateHost = (hostname: string, allowedHosts: string[]): boolean | string => {
  // Check for empty string or spaces only
  const trimmedHostname = hostname.trim();
  if (trimmedHostname === '') {
    return 'Host cannot be an empty string';
  }

  // Check for existing host
  if (allowedHosts.includes(trimmedHostname)) {
    return 'Host is already present';
  }

  // Make sure we have a host and port
  // IPv6 addresses have colons in them so verify if the string is a valid IPv6 address first
  if (!trimmedHostname.includes(':') || trimmedHostname.split(':').length < 2 || isIPv6(trimmedHostname)) {
    return 'Hostname must include host and port in format host:port';
  }

  const [host, port] = splitHostAndPort(trimmedHostname);

  const validHost = isValidHost(host);
  const validPort = isValidPort(port);

  if (validHost !== true) {
    return validHost;
  }
  if (validPort !== true) {
    return validPort;
  }

  return true;
};

// To help validation of hostnames, IPv4, IPv6 and ports, lift this code from the Node.js net package.
// https://github.com/nodejs/node/blob/e8810b91f1dd41e50c089b615ff4b43deffa8ecc/lib/internal/net.js#L46
// IPv4 Segment
const v4Seg = '(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])';
const v4Str = `(${v4Seg}[.]){3}${v4Seg}`;
const IPv4Reg = new RegExp(`^${v4Str}$`);

// IPv6 Segment
const v6Seg = '(?:[0-9a-fA-F]{1,4})';
const IPv6Reg = new RegExp(
  '^\\[(' +
    `(?:${v6Seg}:){7}(?:${v6Seg}|:)|` +
    `(?:${v6Seg}:){6}(?:${v4Str}|:${v6Seg}|:)|` +
    `(?:${v6Seg}:){5}(?::${v4Str}|(:${v6Seg}){1,2}|:)|` +
    `(?:${v6Seg}:){4}(?:(:${v6Seg}){0,1}:${v4Str}|(:${v6Seg}){1,3}|:)|` +
    `(?:${v6Seg}:){3}(?:(:${v6Seg}){0,2}:${v4Str}|(:${v6Seg}){1,4}|:)|` +
    `(?:${v6Seg}:){2}(?:(:${v6Seg}){0,3}:${v4Str}|(:${v6Seg}){1,5}|:)|` +
    `(?:${v6Seg}:){1}(?:(:${v6Seg}){0,4}:${v4Str}|(:${v6Seg}){1,6}|:)|` +
    `(?::((?::${v6Seg}){0,5}:${v4Str}|(?::${v6Seg}){1,7}|:))` +
    ')(%[0-9a-zA-Z-.:]{1,})?\\]$'
);

const isIPv4 = (s: string): boolean => {
  return new RegExp(IPv4Reg).test(s);
};

const isIPv6 = (s: string): boolean => {
  return new RegExp(IPv6Reg).test(s);
};

// Based on
// https://github.com/validatorjs/validator.js/blob/b958bd7d1026a434ad3bf90064d3dcb8b775f1a9/src/lib/isFQDN.js#L13
const isDomain = (s: string): boolean | string => {
  // Limit length of domain to 255 characters
  if (s.length > 255) {
    return 'Domain cannot be longer than 255 characters';
  }
  // Disallow trailing dots
  if (s.endsWith('.')) {
    return 'Domain cannot end with a dot';
  }
  // Disallow spaces
  if (/\s/.test(s)) {
    return 'Domain cannot contain spaces';
  }
  // Strip wildcards out
  if (s.startsWith('*.')) {
    s = s.slice(2);
  }

  const urlParts = s.split('.');
  for (const part of urlParts) {
    if (part === '') {
      return 'Domain cannot have empty parts';
    }
    if (part.length > 63) {
      return 'Subdomains cannot be longer than 63 characters each';
    }
    if (!/^[a-z_\u00a1-\uffff0-9-]+$/i.test(part)) {
      return 'Domain or subdomain must only contain alphanumeric chars, underscore or hyphen';
    }
    // Disallow full-width chars
    if (/[\uff01-\uff5e]/.test(part)) {
      return 'Domain or subdomain cannot contain full-width chars';
    }
    // Disallow parts starting or ending with hyphen
    if (/^-|-$/.test(part)) {
      return 'Domain or subdomain cannot start or end with hyphen';
    }
  }
  return true;
};

const isIP = (s: string) => {
  if (isIPv4(s)) {
    return 4;
  }
  if (isIPv6(s)) {
    return 6;
  }
  return 0;
};

const isValidHost = (host: string): boolean | string => {
  const trimmedHost = host.trim();
  if (trimmedHost === '') {
    return 'Host cannot be an empty string';
  }
  if (trimmedHost === '*') {
    return true;
  }
  if (isIP(trimmedHost) > 0) {
    return true;
  }
  return isDomain(trimmedHost);
};

const isValidPort = (port: string): boolean | string => {
  if (port.trim() === '') {
    return 'Port cannot be an empty string';
  }
  if (isNaN(Number(port)) && port !== '*') {
    return 'Port must be a number or *';
  }
  if (!isNaN(Number(port)) && (Number(port) < 0 || Number(port) > 65535)) {
    return 'Port must be between 0 and 65535';
  }
  return true;
};

const splitHostAndPort = (host: string): [string, string] => {
  const separator = host.lastIndexOf(':');
  const hostname = host.slice(0, separator);
  const port = host.slice(separator + 1);
  return [hostname, port];
};
