import { css } from '@emotion/css';
import React, { useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, useStyles2 } from '@grafana/ui';

import { ConnectionStatus } from 'feature/common';
import { EditPrivateNetworkModal } from 'feature/private-networks/components/PrivateNetworkList/EditPrivateNetworkModal';
import { PrivateNetwork } from 'feature/private-networks/types';

type Props = {
  privateNetwork: PrivateNetwork;
};

export const InformationSection = ({ privateNetwork }: Props) => {
  const styles = useStyles2(getStyles);
  const renderInformationListItem = (title: string, value: string | JSX.Element) => {
    return (
      <>
        <p className={styles.boldText}>{title}</p>
        <p>{value}</p>
      </>
    );
  };

  const [editPrivateNetwork, setEditPrivateNetwork] = useState<PrivateNetwork | undefined>();

  return (
    <>
      {privateNetwork && (
        <section className={styles.section}>
          <h2>Information</h2>
          <div className={styles.infoSection}>
            <div className={styles.infoTable}>
              {renderInformationListItem(
                'Name',
                <p className={styles.plainText}>
                  {privateNetwork.displayName}{' '}
                  <Button
                    fill="text"
                    icon="edit"
                    variant="secondary"
                    onClick={() => {
                      setEditPrivateNetwork(privateNetwork);
                    }}
                  />
                </p>
              )}
              {renderInformationListItem(
                'Connection status',
                <ConnectionStatus privateNetworkId={privateNetwork.id} showRefresh={true} />
              )}
              {renderInformationListItem('ID', privateNetwork.id)}
              {renderInformationListItem(
                'Creation date',
                new Intl.DateTimeFormat('default', { timeStyle: 'medium', dateStyle: 'short' }).format(
                  new Date(privateNetwork.createdAt)
                )
              )}
            </div>
          </div>
          <EditPrivateNetworkModal
            privateNetwork={editPrivateNetwork}
            onClose={() => setEditPrivateNetwork(undefined)}
          />
        </section>
      )}
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  boldText: css({
    fontWeight: 600,
  }),
  section: css({
    marginBottom: '3rem',
  }),
  infoSection: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
  infoTable: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '1rem',
    '> p': {
      margin: 0,
    },
  }),
  linkButton: css({
    background: 'none',
    border: 'none',
    padding: 0,
    paddingBottom: '0.1rem',
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    ':hover': {
      background: 'none',
      color: theme.colors.text.link,
    },
  }),
  plainText: css({
    margin: 0,
  }),
});
