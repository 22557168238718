import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { store } from '../store';

import { AppApi, GCOM_URL } from './AppApi';

interface Instance {
  slug: string;
}

const tagType = 'instances';
const url = GCOM_URL + '/instances';

const InstanceApi = AppApi.enhanceEndpoints({
  addTagTypes: [tagType],
}).injectEndpoints({
  endpoints: (build) => ({
    getInstance: build.query<Instance, void>({
      query() {
        return {
          url,
          method: 'GET',
        };
      },
      providesTags: [tagType],
    }),
  }),
});

function getCachedInstanceData(): Instance {
  return store.getState().app.queries['getInstance(undefined)']?.data as Instance;
}

export const getInstanceResult = async (
  baseQuery: (arg: string | FetchArgs) => MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError>>
) => {
  const cachedInstanceData = getCachedInstanceData();
  if (cachedInstanceData) {
    return {
      data: cachedInstanceData,
    };
  } else {
    return await baseQuery({
      url: GCOM_URL + '/instances',
    });
  }
};

export const { useGetInstanceQuery } = InstanceApi;
