import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

type Props = {
  children: React.ReactNode;
  href: string;
  rel?: string;
  target?: string;
  onClick?: () => void;
};

export const TextLink = ({ children, href, rel = 'noreferrer', target = '_blank', onClick }: Props) => {
  const styles = useStyles2(getStyles);

  return (
    <a href={href} rel={rel} target={target} className={styles.textLink} onClick={onClick}>
      {children}
    </a>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  textLink: css({
    color: theme.colors.text.link,
    ':hover': {
      textDecoration: 'underline',
    },
  }),
});
