import React from 'react';

import { DataSource } from 'feature/common';
import { DataSourcesItem } from 'feature/private-networks/components';

type Props = {
  dataSources: DataSource[];
};

export const DataSourcesList = ({ dataSources }: Props) => {
  return (
    <>
      {dataSources.map((dataSource) => (
        <DataSourcesItem key={dataSource.id} dataSource={dataSource} />
      ))}
    </>
  );
};
