import { css } from '@emotion/css';
import React, { useContext } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Field, Form, Input, Modal, Spinner, useStyles2 } from '@grafana/ui';

import { QueryError } from '@grafana-cloud/grafana-com-api';
import { Alert } from '@grafana-cloud/ui';
import { useGetInstanceQuery } from 'feature/common/api/InstanceApi';
import { AppContext } from 'feature/common/components/AppContext';
import { validateConnectionName } from 'feature/common/utils/name';
import {
  useCreatePrivateNetworkMutation,
  useGetPrivateNetworksQuery,
} from 'feature/private-networks/api/PrivateNetworksApi';
import { PrivateNetwork } from 'feature/private-networks/types/PrivateNetwork';
import { getRandomConnectionName } from 'feature/private-networks/utils';

type Props = {
  onClose: (privateNetwork?: PrivateNetwork) => void;
  isOpen: boolean;
};

export const CreatePrivateNetworkModal = ({ onClose, isOpen }: Props) => {
  const styles = useStyles2(getStyles);

  const { region, stackId } = useContext(AppContext);
  const [mutate, { error, isError, isLoading }] = useCreatePrivateNetworkMutation();
  const { data } = useGetPrivateNetworksQuery({ region, stackId });

  // Check both names and display names
  const existingNames = data ? data.map((item: PrivateNetwork) => [item.name, item.displayName]).flat() : [];

  const { data: instance } = useGetInstanceQuery();

  const createPrivateNetworks = (privateNetwork: Partial<PrivateNetwork>) => {
    mutate({
      privateNetwork,
      region,
      stackId,
    })
      .unwrap()
      .then((createdNetwork) => {
        onClose(createdNetwork);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClickBackdrop={() => onClose()}
      onDismiss={() => onClose()}
      title="Connect to a new network"
    >
      <Form
        validateOn="onChange"
        // onSubmit is required but we're not actually using it.
        // When this form is embedded in a modal in the data source config page,
        // using a 'submit' button accidentally causes the entire data source to submit and be updated
        // when the user clicks the 'Add' button.
        // This creates pain so we've removed the submit button and are using a button with an onClick listener instead.
        onSubmit={(formData: { connectionName: string }) => {
          createPrivateNetworks({ displayName: formData.connectionName });
        }}
      >
        {({ register, errors, getValues }) => {
          return (
            <>
              <Field label="Name" invalid={!!errors.connectionName} error={errors?.connectionName?.message}>
                <Input
                  type="text"
                  id="connectionName"
                  aria-label="Connection name"
                  placeholder="Connection name"
                  defaultValue={getRandomConnectionName(instance?.slug || '')}
                  {...register('connectionName', {
                    validate: (value) => validateConnectionName(value, existingNames),
                    required: { value: true, message: 'Connection name is required' },
                  })}
                ></Input>
              </Field>
              <div className={styles.modalButtons}>
                <Button
                  onClick={() => createPrivateNetworks({ displayName: getValues('connectionName') })}
                  className={styles.submitBtn}
                  disabled={!!errors.connectionName || isLoading}
                >
                  {isLoading && <Spinner className={styles.spinner} />}
                  Add
                </Button>
                <Button onClick={() => onClose()} variant="secondary">
                  Cancel
                </Button>
              </div>
              {isError && (
                <Alert status="error">
                  Error when creating private data source connect - {(error as QueryError).data?.message}
                </Alert>
              )}
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  submitBtn: css({
    marginRight: theme.spacing(2),
  }),
  modalButtons: css({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  spinner: css({
    marginRight: theme.spacing(1),
  }),
});
