import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';

import { createApi } from './AppApiContext';

const PLUGIN_PROXY_URL = 'api/plugin-proxy/grafana-pdc-app/';
export const GCOM_URL = PLUGIN_PROXY_URL + 'grafanacom-api';
export const AUTH_URL = GCOM_URL + '/v1/';
export const PDC_API_URL = PLUGIN_PROXY_URL + `pdc-api/pdc/api/v1/`;

export const AppApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  reducerPath: 'app',
  endpoints: () => ({}),
});
