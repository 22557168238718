import { FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import groupBy from 'lodash/groupBy';

import { DataSourceSettings } from '@grafana/data';

import { AccessPolicy } from '@grafana-cloud/access-policies';
import { DataSource, PDCDataSourceJSONData, getGrafanaStore, getRandomId } from 'feature/common';
import { AUTH_URL, AppApi, PDC_API_URL } from 'feature/common/api/AppApi';
import { getInstanceResult } from 'feature/common/api/InstanceApi';
import { PrivateNetworkDataSource } from 'feature/private-networks/types';
import { getDefaultAccessPolicyName } from 'feature/private-networks/utils';

interface GetDataSourcesQueryResult {
  allDataSources: DataSource[];
  allDataSourcesById: DataSourceMap;
  dataSourcesByPrivateNetwork: DataSourceMap;
  disabledDataSources: DataSource[];
  enabledDataSources: DataSource[];
  dsToNetworkResult: PrivateNetworkDataSource[];
}

interface DataSourceMap {
  [key: string]: DataSource[];
}

async function getDefaultAccessPolicy(
  region: string,
  baseQuery: (arg: string | FetchArgs) => MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError>>
): Promise<{
  data?: AccessPolicy;
  error?: FetchBaseQueryError;
}> {
  const instanceResult = await getInstanceResult(baseQuery);

  if (instanceResult.error) {
    return {
      error: instanceResult.error,
    };
  }

  const instanceData = instanceResult.data as { slug: string };
  const DEFAULT_PRIVATE_NETWORK_NAME = getDefaultAccessPolicyName(instanceData.slug);
  const findResult = await baseQuery({
    url: AUTH_URL + 'accesspolicies',
    params: {
      name: DEFAULT_PRIVATE_NETWORK_NAME,
      region,
    },
  });

  if (findResult.error) {
    return findResult;
  }

  const findResultData = findResult.data as { items: AccessPolicy[] };
  return {
    data: findResultData?.items?.[0],
  };
}

export const allDataSourcesTagType = 'all-datasources';
const individualDSTagType = 'individual-datasource';
const url = 'api/datasources';

export const DataSourceApi = AppApi.enhanceEndpoints({
  addTagTypes: [allDataSourcesTagType, individualDSTagType],
}).injectEndpoints({
  endpoints: (build) => ({
    getDataSources: build.query<GetDataSourcesQueryResult, { region: string }>({
      async queryFn({ region }, api, extraOptions, baseQuery) {
        // trying to get cached data from grafana first
        // this is a bit hacky as the grafana store state is not exported officially
        let allDataSources = getGrafanaStore()?.getState()?.dataSources?.dataSources as DataSource[];
        // update current data source in case it was changed
        let currentDataSource = getGrafanaStore()?.getState()?.dataSources?.dataSource as DataSource;
        allDataSources = allDataSources?.map((ds) => {
          if (ds.id === currentDataSource?.id) {
            return currentDataSource;
          }
          return ds;
        });

        if (!allDataSources || allDataSources.length === 0) {
          const allDataSourcesResponse = await baseQuery({
            url: url,
          });

          if (allDataSourcesResponse.error) {
            return allDataSourcesResponse;
          }
          allDataSources = allDataSourcesResponse.data as DataSource[];
        }

        const enabledDataSources = [];
        const disabledDataSources = [];

        for (const ds of allDataSources) {
          if (ds.jsonData.enableSecureSocksProxy) {
            enabledDataSources.push(ds);
          } else {
            disabledDataSources.push(ds);
          }
        }

        const dsToNetworkResult: PrivateNetworkDataSource[] = enabledDataSources.map((ds) => ({
          ID: ds.id,
          networkID: ds.jsonData.secureSocksProxyUsername,
          UID: getRandomId(),
        }));

        if (dsToNetworkResult.some((ds) => !ds.networkID)) {
          const defaultAccessPolicyResult = await getDefaultAccessPolicy(region, baseQuery);
          if (defaultAccessPolicyResult.error) {
            return {
              error: defaultAccessPolicyResult.error,
            };
          }
          dsToNetworkResult.map((network) => {
            if (!network.networkID) {
              network.networkID = defaultAccessPolicyResult.data?.id;
            }
          });
        }

        const dsToNetworkMap = groupBy(dsToNetworkResult, (ds) => ds.ID);

        return {
          data: {
            allDataSources,
            allDataSourcesById: groupBy(allDataSources, (ds) => String(ds.id)),
            dataSourcesByPrivateNetwork: groupBy(enabledDataSources, (ds) => dsToNetworkMap[ds.id]?.[0]?.networkID),
            disabledDataSources,
            enabledDataSources,
            dsToNetworkResult,
          },
        };
      },
      providesTags: [allDataSourcesTagType],
    }),
    isDataSourceSupported: build.query<boolean, { pluginType: string; pluginVersion: string; grafanaVersion: string }>({
      query({ pluginType, pluginVersion, grafanaVersion }) {
        return {
          url: PDC_API_URL + 'datasource-supported/' + pluginType,
          params: {
            pluginVersion,
            grafanaVersion,
          },
          method: 'GET',
        };
      },
    }),
    getPrivateNetworkDataSource: build.query<
      PrivateNetworkDataSource,
      { region: string; dataSource: DataSourceSettings<PDCDataSourceJSONData, {}> }
    >({
      async queryFn({ region, dataSource }, api, extraOptions, baseQuery) {
        // Fail fast
        if (!dataSource.jsonData.enableSecureSocksProxy) {
          return {
            error: {
              data: 'Private network is not enabled for this data source',
              status: 403,
            },
          };
        }

        if (dataSource.jsonData.secureSocksProxyUsername) {
          return {
            data: {
              ID: dataSource.id,
              networkID: dataSource.jsonData.secureSocksProxyUsername,
              UID: getRandomId(),
            },
          };
        }
        const defaultAccessPolicyResult = await getDefaultAccessPolicy(region, baseQuery);
        if (defaultAccessPolicyResult.error) {
          return {
            error: defaultAccessPolicyResult.error,
          };
        }

        return {
          data: {
            ID: dataSource.id,
            networkID: defaultAccessPolicyResult.data?.id,
            UID: getRandomId(),
          },
        };
      },
      providesTags: [individualDSTagType],
    }),
  }),
});

export const { useGetDataSourcesQuery, useIsDataSourceSupportedQuery, useGetPrivateNetworkDataSourceQuery } =
  DataSourceApi;
