import { AccessPolicyModel } from '@grafana-cloud/access-policies';
import { Instance } from '@grafana-cloud/grafana-com-api';
import { AUTH_URL, AppApi } from 'feature/common/api/AppApi';
import { getInstanceResult } from 'feature/common/api/InstanceApi';
import { isAdmin } from 'feature/common/utils/user';
import { PrivateNetwork } from 'feature/private-networks/types';
import {
  LEGACY_PDC_SIGNING_SCOPE,
  PDC_SIGNING_SCOPE,
  accessPolicyToPrivateNetwork,
  getDefaultAccessPolicyName,
  privateNetworkToAccessPolicyModel,
} from 'feature/private-networks/utils';

import { CONNECTION_STATUS_TAG_TYPE } from './ConnectionStatusApi';

const url = AUTH_URL + 'accesspolicies';
const tagType = 'privatenetworks';
interface MutationPayload {
  region: string;
  stackId: string;
  privateNetwork: Partial<PrivateNetwork>;
}

const PrivateNetworksApi = AppApi.enhanceEndpoints({
  addTagTypes: [tagType, CONNECTION_STATUS_TAG_TYPE],
}).injectEndpoints({
  endpoints: (build) => ({
    getPrivateNetwork: build.query<PrivateNetwork, { region: string; privateNetworkId: string }>({
      query: ({ region, privateNetworkId }) => ({
        url: url + '/' + privateNetworkId,
        method: 'GET',
        params: { region },
      }),
      transformResponse: (response: AccessPolicyModel) => accessPolicyToPrivateNetwork(response),
      providesTags: [tagType],
    }),
    getPrivateNetworks: build.query<PrivateNetwork[], { region: string; stackId: string }>({
      async queryFn({ region, stackId }, api, extraOptions, baseQuery) {
        const accessPoliciesResult = await baseQuery({
          url,
          params: {
            region,
          },
        });

        if (accessPoliciesResult.error) {
          return accessPoliciesResult;
        }

        const accessPoliciesData = accessPoliciesResult.data as { items: AccessPolicyModel[] };

        const pdcSigningAccessPolicies = accessPoliciesData.items.filter((ap) =>
          ap.scopes.find((policy) => [PDC_SIGNING_SCOPE, LEGACY_PDC_SIGNING_SCOPE].includes(policy))
        );

        // returning private networks if there are any
        if (pdcSigningAccessPolicies.length > 0) {
          return {
            data: pdcSigningAccessPolicies.map((item) => accessPolicyToPrivateNetwork(item)),
          };
        } else {
          // try to get cached instance data first to prevent unnecessary requests
          const instanceResult = await getInstanceResult(baseQuery);

          if (instanceResult.error) {
            return instanceResult;
          }

          // prevent creating default network if the user is not an admin
          if (!isAdmin()) {
            return {
              data: [],
            };
          }

          const instanceData = instanceResult.data as Instance;

          const DEFAULT_PRIVATE_NETWORK_NAME = getDefaultAccessPolicyName(instanceData.slug);

          const newPrivateNetworkResult = await baseQuery({
            url,
            method: 'POST',
            params: { region },
            body: {
              ...privateNetworkToAccessPolicyModel(
                {
                  name: DEFAULT_PRIVATE_NETWORK_NAME,
                },
                stackId
              ),
            },
          });

          if (newPrivateNetworkResult.error) {
            return newPrivateNetworkResult;
          }

          const newAccessPolicyData = newPrivateNetworkResult.data as AccessPolicyModel;

          return { data: [accessPolicyToPrivateNetwork(newAccessPolicyData)] };
        }
      },
      providesTags: [tagType],
    }),
    createPrivateNetwork: build.mutation<PrivateNetwork, MutationPayload>({
      query({ privateNetwork, region, stackId }) {
        return {
          url,
          method: 'POST',
          params: { region },
          transformResponse: (response: AccessPolicyModel) => accessPolicyToPrivateNetwork(response),
          body: {
            ...privateNetworkToAccessPolicyModel(privateNetwork, stackId),
          },
        };
      },
      invalidatesTags: [tagType, CONNECTION_STATUS_TAG_TYPE],
    }),
    updatePrivateNetwork: build.mutation<PrivateNetwork, MutationPayload>({
      query({ privateNetwork, region, stackId }) {
        // Only the displayName and allowedHosts can be updated
        const { displayName, attributes } = privateNetworkToAccessPolicyModel(privateNetwork, stackId);
        return {
          url: url + '/' + privateNetwork.id,
          method: 'POST',
          params: { region },
          body: {
            displayName,
            attributes,
          },
          transformResponse: (response: AccessPolicyModel) => accessPolicyToPrivateNetwork(response),
        };
      },
      async onQueryStarted({ privateNetwork, region, stackId }, { dispatch, queryFulfilled }) {
        const patchResult = await dispatch(
          PrivateNetworksApi.util.updateQueryData('getPrivateNetworks', { region, stackId }, (draft) => {
            for (const network of draft) {
              if (network.id === privateNetwork.id) {
                Object.assign(network, privateNetwork);
              }
            }
          })
        );
        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
          console.error('Error updating private network', error);
          dispatch(PrivateNetworksApi.util.invalidateTags([tagType])); // Force a re-fetch if it fails
        }
      },
    }),
    deletePrivateNetwork: build.mutation<PrivateNetwork, MutationPayload>({
      query({ privateNetwork, region }) {
        return {
          url: url + '/' + privateNetwork.id,
          method: 'DELETE',
          params: { region },
          transformResponse: (response: AccessPolicyModel) => accessPolicyToPrivateNetwork(response),
        };
      },
      invalidatesTags: [tagType, CONNECTION_STATUS_TAG_TYPE],
    }),
  }),
});

export const {
  useGetPrivateNetworksQuery,
  useCreatePrivateNetworkMutation,
  useDeletePrivateNetworkMutation,
  useUpdatePrivateNetworkMutation,
} = PrivateNetworksApi;
