import { css } from '@emotion/css';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { GrafanaTheme2 } from '@grafana/data';
import { PluginPage } from '@grafana/runtime';
import { Alert, Button, Icon, Input, LoadingPlaceholder, Select, useStyles2 } from '@grafana/ui';

import { QueryError } from '@grafana-cloud/grafana-com-api';
import { PDC_DOCS_URL, PRIVATE_NETWORKS_PATH, SORT_OPTIONS, SortType } from 'consts';
import { TextLink, useRudderStack } from 'feature/common';
import { AppContext } from 'feature/common/components/AppContext';
import { useGetConnectionStatusQuery } from 'feature/private-networks/api/ConnectionStatusApi';
import { useGetPrivateNetworksQuery } from 'feature/private-networks/api/PrivateNetworksApi';
import { Tabs } from 'feature/private-networks/components/PrivateNetworkDetail/Tabs/Tabs';
import { PrivateNetworkList } from 'feature/private-networks/components/PrivateNetworkList';
import { useTokens } from 'feature/private-networks/hooks/useTokens';
import { PrivateNetwork } from 'feature/private-networks/types';

import { useHadConnectedAgentsMutation } from '../../api/ConnectedAgentsApi';

import { CreatePrivateNetworkModal } from './CreatePrivateNetworkModal';

export const PrivateNetworks = () => {
  const { region, stackId } = useContext(AppContext);
  const { data: networks, error, isLoading: isNetworksLoading } = useGetPrivateNetworksQuery({ region, stackId });

  // preload tokens so that we can do this in parallel
  useTokens();

  const {
    data: connectionStatuses,
    isSuccess: isConnectionStatusSuccess,
    isLoading: isConnectionStatusLoading,
  } = useGetConnectionStatusQuery();
  const [
    checkAgents,
    {
      data: previouslyConnectedAgentsData,
      isSuccess: isHadConnectedAgentsSuccess,
      isLoading: isHadConnectedAgentsLoading,
    },
  ] = useHadConnectedAgentsMutation();

  const hasNoActiveConnectedAgents =
    isConnectionStatusSuccess &&
    connectionStatuses &&
    !connectionStatuses?.items.some((item) => item.numConnectedAgents > 0);

  useEffect(() => {
    if (hasNoActiveConnectedAgents) {
      checkAgents();
    }
  }, [checkAgents, hasNoActiveConnectedAgents]);

  const hadNoConnectedAgents =
    isHadConnectedAgentsSuccess && previouslyConnectedAgentsData === false && hasNoActiveConnectedAgents;

  const { trackRudderStackEvent } = useRudderStack();

  const [createPrivateNetwork, setCreatePrivateNetwork] = useState<boolean>(false);

  const styles = useStyles2(getStyles);

  const [filter, setFilter] = useState('');
  const hasData = networks && networks.length > 0;

  const [selectedSortType, setSelectedSortType] = useState(SortType.CreatedDate);
  const [createdNetwork, setCreatedNetwork] = useState<PrivateNetwork | undefined>(undefined);

  const addButton = (
    <Button className={styles.addButton} onClick={() => setCreatePrivateNetwork(true)}>
      <Icon className={styles.plusIcon} name="plus" size="lg" />
      Add new network
    </Button>
  );

  const networkList = hasData && (
    <>
      <div className={styles.searchSortWrapper}>
        <Input
          className={styles.searchInput}
          onChange={(ev: React.FormEvent<HTMLInputElement>) => setFilter(ev.currentTarget.value)}
          prefix={<Icon name="search" />}
          placeholder="Search all"
          aria-label="Search private data source connect"
        />
        <Select
          prefix={<Icon name="sort-amount-down" />}
          options={SORT_OPTIONS}
          value={selectedSortType}
          className={styles.selectSort}
          onChange={(selected) => setSelectedSortType(selected.value as SortType)}
        />
      </div>
      <PrivateNetworkList filter={filter} sort={selectedSortType} />
    </>
  );

  const errorAlert = error && (
    <Alert severity="error" title="Error when loading private data source connect">
      {(error as QueryError).data?.message}
    </Alert>
  );

  const isLoading = isConnectionStatusLoading || isHadConnectedAgentsLoading || isNetworksLoading;

  const subTitle = (
    <>
      Query data that lives within a secured network without opening the network to inbound traffic from Grafana Cloud.
      Learn more in our{' '}
      <TextLink
        href={PDC_DOCS_URL}
        onClick={() =>
          trackRudderStackEvent('grafana_pdc_plugin_documentation_link_clicked', {
            location: 'private_network_list_page_subtitle',
          })
        }
      >
        docs
      </TextLink>
      .
    </>
  );

  const renderTitle = () => {
    return (
      <div className={styles.title}>
        <Icon name="lock" size="xl" />
        <h1 className={styles.mainTitle}>Private data source connect</h1>
      </div>
    );
  };

  if (networks?.length === 1 && hadNoConnectedAgents) {
    const defaultDetailUrl: string = PRIVATE_NETWORKS_PATH + '/' + networks[0].id + '?tab=' + Tabs.ConfigurationDetails;
    return <Navigate to={defaultDetailUrl} />;
  }

  if (!!createdNetwork) {
    const detailUrl: string = PRIVATE_NETWORKS_PATH + '/' + createdNetwork.id + '?tab=' + Tabs.ConfigurationDetails;
    return <Navigate to={detailUrl} />;
  }

  return (
    <PluginPage actions={hasData && !isLoading ? addButton : undefined} subTitle={subTitle} renderTitle={renderTitle}>
      {errorAlert}
      {isLoading ? <LoadingPlaceholder text="Loading ..." /> : networkList}
      <CreatePrivateNetworkModal
        isOpen={createPrivateNetwork}
        onClose={(createdPrivateNetwork) => {
          setCreatePrivateNetwork(false);
          setCreatedNetwork(createdPrivateNetwork);
          trackRudderStackEvent('grafana_pdc_plugin_list_create_connection', {
            connection_id: createdPrivateNetwork?.id,
          });
        }}
      />
    </PluginPage>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  searchInput: css({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }),
  addButton: css({
    '@media (max-width: 640px)': {
      width: '100%',
      marginTop: theme.spacing(2),
    },
  }),
  networkTable: css({
    display: 'grid',
    gridTemplateColumns: '0.5fr 2fr 1.5fr 1.5fr 0.5fr',
    gridRowGap: theme.spacing(2),
    alignItems: 'center',
    '> *': {
      padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    },
  }),
  networkTableHover: css({
    '&:hover': {
      backgroundColor: theme.colors.background.secondary,
    },
  }),
  networkTableItemName: css({
    gridColumnStart: 2,
  }),
  networkTableItemTokens: css({
    gridColumn: '4 / span 2',
  }),
  infoIcon: css({
    marginLeft: theme.spacing(1),
  }),
  nameInput: css({
    marginBottom: theme.spacing(2),
    maxWidth: 400,
  }),
  plusIcon: css({
    marginRight: theme.spacing(1),
  }),
  selectSort: css({
    marginLeft: theme.spacing(1),
    maxWidth: 200,
  }),
  searchSortWrapper: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  }),
  title: css({
    display: 'flex',
    alignItems: 'center',
  }),
  mainTitle: css({
    marginBottom: 0,
    marginLeft: theme.spacing(1),
  }),
});
