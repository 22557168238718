import { FeatureToggles } from '@grafana/data';
import { config } from '@grafana/runtime';

export enum PDCFeatureToggle {}

export type PDCFeatureToggles = FeatureToggles & {
  [key in PDCFeatureToggle]: boolean;
};

export function isFeatureToggleEnabled(featureName: PDCFeatureToggle): boolean {
  return (config.featureToggles as PDCFeatureToggles)[featureName];
}

export function setFeatureToggle(featureName: PDCFeatureToggle, value: boolean): void {
  (config.featureToggles as PDCFeatureToggles)[featureName] = value;
}

/** Display new form styling for migrated datasources */
export function isNewFormStyling(datasourceType: string): boolean {
  return !!(config.featureToggles.awsDatasourcesNewFormStyling && datasourceType === 'cloudwatch');
}
