import { css } from '@emotion/css';
import React, { useContext } from 'react';

import { GrafanaTheme2, dateTimeFormatTimeAgo } from '@grafana/data';
import { Button, Card, Icon, useStyles2 } from '@grafana/ui';

import { PRIVATE_NETWORKS_PATH } from 'consts';
import { ConnectionStatus, useRudderStack } from 'feature/common';
import { useGetInstanceQuery } from 'feature/common/api/InstanceApi';
import { AppContext } from 'feature/common/components/AppContext';
import { useGetDataSourcesQuery } from 'feature/private-networks/api/DataSourceApi';
import { Tabs } from 'feature/private-networks/components/PrivateNetworkDetail/Tabs/Tabs';
import { useTokens } from 'feature/private-networks/hooks/useTokens';
import { PrivateNetwork } from 'feature/private-networks/types';
import { getDefaultAccessPolicyName } from 'feature/private-networks/utils';

type Props = {
  privateNetwork: PrivateNetwork;
  onDeleteClick: () => void;
};

export const PrivateNetworkListItem = ({ privateNetwork, onDeleteClick }: Props) => {
  const styles = useStyles2(getStyles);
  const { trackRudderStackEvent } = useRudderStack();
  const { region } = useContext(AppContext);

  const { data: dataSources, isLoading: isLoadingDataSources } = useGetDataSourcesQuery({
    region,
  });
  const dataSourcesLength = dataSources?.dataSourcesByPrivateNetwork?.[privateNetwork.id]?.length;
  const { data: instanceData } = useGetInstanceQuery();

  const { data: tokensData, isLoading: tokensIsLoading } = useTokens();

  const privateNetworkTokens = tokensData?.tokensByAccessPolicyId?.[privateNetwork.accessPolicyId] || [];

  const detailUrl: string =
    PRIVATE_NETWORKS_PATH +
    '/' +
    privateNetwork.id +
    '?tab=' +
    (privateNetworkTokens.length > 0 ? Tabs.Overview : Tabs.ConfigurationDetails);

  const isDefaultConnection = instanceData && privateNetwork.name === getDefaultAccessPolicyName(instanceData.slug);

  return (
    <>
      <Card
        href={detailUrl}
        onClick={() =>
          trackRudderStackEvent('grafana_pdc_plugin_list_item_clicked', { connection_id: privateNetwork.id })
        }
      >
        <Card.Heading>
          {privateNetwork.displayName} <ConnectionStatus privateNetworkId={privateNetwork.id} />
        </Card.Heading>
        <Card.Meta className={styles.cardMeta}>
          {[`Created ${dateTimeFormatTimeAgo(privateNetwork.createdAt)}`]}
        </Card.Meta>
        <Card.Tags className={styles.cardTags}>
          <p className={styles.cardText}>
            <Icon className={styles.icon} name="database" size="lg" />
            {isLoadingDataSources ? '...' : dataSourcesLength ?? '0'} data source{dataSourcesLength !== 1 && 's'}
          </p>
          <p className={styles.cardText}>
            <Icon className={styles.icon} name="key-skeleton-alt" size="lg"></Icon>
            {tokensIsLoading ? '...' : privateNetworkTokens.length ?? '0'} token
            {privateNetworkTokens.length !== 1 && 's'}
          </p>
          <Button
            icon="trash-alt"
            fill="outline"
            variant="secondary"
            onClick={() => onDeleteClick()}
            disabled={isDefaultConnection}
            className={styles.disabledButton}
            tooltip={isDefaultConnection ? 'You cannot delete the default connection' : undefined}
          >
            Delete
          </Button>
        </Card.Tags>
      </Card>
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  cardText: css({
    margin: 0,
    fontWeight: theme.typography.fontWeightBold,
    display: 'flex',
    alignItems: 'center',
    width: 130,
  }),
  subText: css({
    margin: 0,
    fontSize: theme.typography.bodySmall.fontSize,
  }),
  deleteButton: css({
    display: 'flex',
    justifyContent: 'end',
    flexWrap: 'wrap',
    alignContent: 'center',
  }),
  cardMeta: css({
    pointerEvents: 'none',
  }),
  icon: css({
    marginRight: '0.5rem',
  }),
  listCard: css({
    background: theme.colors.background.secondary,
    borderRadius: theme.shape.radius.default,
    padding: theme.spacing(2, 3),
    marginBottom: theme.spacing(1),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 0.5fr',
    '&:hover': {
      backgroundColor: theme.colors.action.hover,
    },
  }),
  cardTags: css({
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(5),
    pointerEvents: 'none',
  }),
  disabledButton: css({
    pointerEvents: 'auto',
  }),
});
