import React from 'react';

import { Modal } from '@grafana/ui';

import { CreateTokenForm } from 'feature/common/components/CreateTokenForm';

type Props = {
  onClose: () => void;
  isOpen: boolean;
};

export const TokensCreate = ({ onClose, isOpen }: Props) => {
  return (
    <Modal isOpen={isOpen} onClickBackdrop={onClose} onDismiss={onClose} title="Create new PDC signing token">
      <CreateTokenForm onClose={onClose} trackingLocation="modal" />
    </Modal>
  );
};
