import { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { AppContext } from 'feature/common/components';

if (process.env.NODE_ENV === 'development') {
  // Log to console in development mode
  (window as any).rudderanalytics = { track: console.log };
}

export const useRudderStack = () => {
  const { stackId, pdcClusterSlug } = useContext(AppContext);
  const { id: privateNetworkId } = useParams<{ id: string }>();

  const trackRudderStackEvent = useCallback(
    (event: string, params: object) => {
      (window as any).rudderanalytics?.track(event, {
        connection_id: privateNetworkId,
        plugin_id: 'pdc',
        stack_id: stackId,
        pdc_cluster_slug: pdcClusterSlug,
        ...params,
      });
    },
    [stackId, pdcClusterSlug, privateNetworkId]
  );

  return {
    trackRudderStackEvent,
  };
};
