import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { LinkButton, useStyles2 } from '@grafana/ui';

import { TextLink } from 'feature/common/components/TextLink';

export const ConfigurationInstructionsAssignDataSource = () => {
  const styles = useStyles2(getStyles);
  return (
    <>
      <h5>Assign a new or existing data source to your network</h5>
      <p>
        <TextLink href="/connections/datasources/new">Create a new data source</TextLink> or use{' '}
        <TextLink href="/connections/datasources">an existing data source</TextLink> and configure it to use your
        network. At the bottom of the data source configuraiton page, select the private network and click Test.
      </p>
      <LinkButton className={styles.linkButton} href="/connections/datasources" rel="noreferrer" target="_blank">
        Go to data sources
      </LinkButton>
      <LinkButton variant="secondary" href="/connections/datasources/new" rel="noreferrer" target="_blank">
        Create a new data source
      </LinkButton>
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  linkButton: css({
    marginRight: theme.spacing(2),
  }),
});
