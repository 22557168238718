import { css } from '@emotion/css';
import React from 'react';
import { useParams } from 'react-router-dom';

import { GrafanaTheme2 } from '@grafana/data';
import { RadioButtonGroup, useStyles2 } from '@grafana/ui';

import { useAppDispatch, useAppSelector, useRudderStack } from 'feature/common';
import { CreateTokenForm } from 'feature/common/components/CreateTokenForm';
import { TokenType } from 'feature/common/types';
import { ConfigurationInstructionsUseTokenExisting } from 'feature/private-networks/components';
import {
  getPrivateNetworkDetailState,
  setExistingPdcToken,
  setTokenType,
} from 'feature/private-networks/state/privateNetworkDetailSlice';

const instructionOptions = [
  { label: 'Create a new token', value: TokenType.NewToken },
  { label: 'Use an existing token', value: TokenType.ExistingToken },
];

export const ConfigurationInstructionsUseToken = () => {
  const styles = useStyles2(getStyles);
  const { trackRudderStackEvent } = useRudderStack();
  const { id: privateNetworkId = '' } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();
  const tokenType = useAppSelector((state) => getPrivateNetworkDetailState(state, privateNetworkId).tokenType);

  const handleOnChange = (value: TokenType) => {
    dispatch(
      setTokenType({
        tokenType: value,
        id: privateNetworkId,
      })
    );
    dispatch(
      setExistingPdcToken({
        existingPdcToken: '',
        id: privateNetworkId,
      })
    );
    trackRudderStackEvent('grafana_pdc_plugin_configuration_token_type_changed', {
      token_type: value,
    });
  };

  return (
    <>
      <>
        <h5>Use a PDC signing token</h5>
        <RadioButtonGroup
          options={instructionOptions}
          value={tokenType}
          className={styles.radioButtons}
          onChange={handleOnChange}
        />
        {tokenType === TokenType.NewToken && (
          <CreateTokenForm
            trackingLocation="configuration_instructions_tab"
            successMessage={
              'Below you find the token you just generated - no need to do anything with it, we’ve automatically added it to the script in the next step.'
            }
          />
        )}
        {tokenType === TokenType.ExistingToken && <ConfigurationInstructionsUseTokenExisting />}
      </>
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  radioButtons: css({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    label: {
      marginRight: 0,
    },
  }),
});
