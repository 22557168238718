import { TokenWithValue } from '@grafana-cloud/access-policies';
import { GCLOUD_PDC_SIGNING_TOKEN_PLACEHOLDER } from 'consts';
import { TokenType } from 'feature/common';

const generateTokenInstruction = (tokenType?: TokenType, pdcToken?: TokenWithValue, existingPdcToken?: string) => {
  const isNewTokenGenerated = tokenType === TokenType.NewToken && pdcToken?.token;
  const isExistingTokenInputed = tokenType === TokenType.ExistingToken && existingPdcToken;
  return isNewTokenGenerated
    ? pdcToken?.token
    : isExistingTokenInputed
    ? existingPdcToken
    : GCLOUD_PDC_SIGNING_TOKEN_PLACEHOLDER;
};

export const generateBinaryInstruction = (
  pdcClusterSlug: string,
  stackId: string,
  allowedHosts?: string[],
  tokenType?: TokenType,
  pdcToken?: TokenWithValue,
  domain?: string,
  existingPdcToken?: string
) => {
  return `./pdc -token ${generateTokenInstruction(
    tokenType,
    pdcToken,
    existingPdcToken
  )} -cluster ${pdcClusterSlug} -gcloud-hosted-grafana-id ${stackId}${domain ? ` -domain ${domain}` : ''}${
    allowedHosts && allowedHosts.length > 0 ? ` -ssh-flag='-o PermitRemoteOpen=${allowedHosts.join(' ')}'` : ''
  }`;
};

export const generateDockerInstruction = (
  pdcClusterSlug: string,
  stackId: string,
  allowedHosts?: string[],
  tokenType?: TokenType,
  pdcToken?: TokenWithValue,
  domain?: string,
  existingPdcToken?: string
) => {
  return `docker run --name pdc-agent grafana/pdc-agent:latest -token ${generateTokenInstruction(
    tokenType,
    pdcToken,
    existingPdcToken
  )} -cluster ${pdcClusterSlug} -gcloud-hosted-grafana-id ${stackId}${domain ? ` -domain ${domain}` : ''}${
    allowedHosts && allowedHosts.length > 0 ? ` -ssh-flag='-o PermitRemoteOpen=${allowedHosts.join(' ')}'` : ''
  }`;
};

export const generateKubernetesInstruction = (
  pdcClusterSlug: string,
  stackId: string,
  tokenType?: TokenType,
  pdcToken?: TokenWithValue,
  existingPdcToken?: string
) => {
  return `kubectl create secret generic grafana-pdc-agent \\\n --from-literal="token=${generateTokenInstruction(
    tokenType,
    pdcToken,
    existingPdcToken
  )}" \\\n --from-literal="hosted-grafana-id=${stackId}" \\\n --from-literal="cluster=${pdcClusterSlug}"`;
};

export const getHighlightLinesForSnippet = (snippet: string, keyword: string) => {
  let codeFirstLineIndex = -1;
  return snippet?.split('\n').reduce((accumulated, element, index) => {
    if (element.includes('```')) {
      codeFirstLineIndex = index;
    }
    if (element.includes(keyword)) {
      accumulated.push(index - codeFirstLineIndex - 1);
    }
    return accumulated;
  }, [] as number[]);
};
