import { useContext, useMemo } from 'react';

import { AppContext } from 'feature/common';

import { useGetPrivateNetworksQuery } from '../api/PrivateNetworksApi';

export function usePrivateNetwork(privateNetworkId: string) {
  const { region, stackId } = useContext(AppContext);
  const { data, error, isError, isLoading, isFetching } = useGetPrivateNetworksQuery({
    region,
    stackId,
  });

  const network = useMemo(() => {
    return data?.find((network) => network.id === privateNetworkId);
  }, [data, privateNetworkId]);

  return {
    error,
    isError,
    isLoading,
    isFetching,
    data: network,
  };
}
