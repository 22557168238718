import React from 'react';
import { useParams } from 'react-router-dom';

import { Field, Input } from '@grafana/ui';

import { useAppDispatch, useAppSelector } from 'feature/common';
import { validateExistingToken } from 'feature/common/utils/token';
import { setExistingPdcToken, setShouldHighlightLines } from 'feature/private-networks/state/privateNetworkDetailSlice';

export const ConfigurationInstructionsUseTokenExisting = () => {
  const dispatch = useAppDispatch();
  const { id: privateNetworkId = '' } = useParams<{ id: string }>();
  const existingToken = useAppSelector((state) => state.privateNetworkDetail[privateNetworkId].existingPdcToken);

  return (
    <Field
      label="Existing token"
      description="Paste your existing token here and we will automatically add it to the script in the next step."
      invalid={validateExistingToken(existingToken) !== true}
      error={validateExistingToken(existingToken)}
    >
      <Input
        type="text"
        id="connectionName"
        aria-label="Existing token"
        placeholder="Existing token"
        onChange={(ev: React.FormEvent<HTMLInputElement>) =>
          dispatch(setExistingPdcToken({ existingPdcToken: ev.currentTarget.value, id: privateNetworkId }))
        }
        onFocus={() => dispatch(setShouldHighlightLines({ shouldHighlightLines: true, id: privateNetworkId }))}
        onBlur={() => dispatch(setShouldHighlightLines({ shouldHighlightLines: false, id: privateNetworkId }))}
      />
    </Field>
  );
};
