import { css, cx } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, IconButton, useStyles2 } from '@grafana/ui';

import { ConnectionStatusApi, useGetConnectionStatusQuery } from 'feature/private-networks/api/ConnectionStatusApi';
import { ConnectionStatus as Status } from 'feature/private-networks/types';
import { getConnectionStatus, getConnectionStatusText } from 'feature/private-networks/utils';

type Props = {
  privateNetworkId: string;
  showRefresh?: boolean;
};

export const ConnectionStatus = ({ privateNetworkId, showRefresh }: Props) => {
  const styles = useStyles2(getStyles);
  // this query is also used in the parent component so checking if it wasn't erroring out
  // and skipping refetch if that's the case. Otherwise we would end up in an infinite loop
  const connectionStatusResult = ConnectionStatusApi.endpoints.getConnectionStatus.useQueryState();
  const { data, isFetching, refetch, isError } = useGetConnectionStatusQuery(undefined, {
    skip: connectionStatusResult.isError,
  });
  const connectionInfo = data?.byId[privateNetworkId]?.[0];
  const numConnectedAgents = connectionInfo?.numConnectedAgents ?? -1;

  const connectionStatus = getConnectionStatus(connectionInfo, isFetching, isError || connectionStatusResult.isError);

  return (
    <div className={styles.statusContainer}>
      {connectionStatus === Status.LOADING && (
        <div className={styles.statusText}>
          <span>{getConnectionStatusText(connectionStatus, numConnectedAgents)}</span>
        </div>
      )}
      {connectionStatus === Status.CONNECTED && (
        <div className={cx(styles.statusText, styles.statusTextSuccess)}>
          <Icon className={styles.statusIcon} name="check-circle" size="lg"></Icon>
          <span> {getConnectionStatusText(connectionStatus, numConnectedAgents)}</span>
        </div>
      )}
      {connectionStatus === Status.NOT_CONNECTED && (
        <div className={styles.statusText}>
          <Icon className={styles.statusIcon} name="info-circle" size="lg"></Icon>
          <span>{getConnectionStatusText(connectionStatus, numConnectedAgents)}</span>
        </div>
      )}
      {connectionStatus === Status.NOT_AVAILABLE && (
        <span className={cx(styles.statusTextError, styles.statusText)}>
          <Icon className={styles.statusIcon} name="question-circle" size="lg"></Icon>
          <span>{getConnectionStatusText(connectionStatus, numConnectedAgents)}</span>
        </span>
      )}
      {showRefresh && (
        <IconButton
          className={styles.refreshButton}
          variant="secondary"
          aria-label="Refresh connection status"
          name="sync"
          title="Refresh connection status"
          onClick={() => refetch()}
        />
      )}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  statusContainer: css({
    display: 'flex',
    alignItems: 'center',
  }),
  statusIcon: css({
    marginRight: '0.5rem',
  }),
  statusText: css({
    display: 'flex',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightBold,
  }),
  statusTextSuccess: css({
    color: '#1A7F4B',
  }),
  statusTextError: css({
    color: theme.colors.warning.text,
  }),
  refreshButton: css({
    marginLeft: theme.spacing(1),
  }),
});
